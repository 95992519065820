import React from "react";

function Posted({ image, name, i }) {
  return (
    <div className=" xl:w-[320px] lg:w-[300px] w-full text-[14px] h-fit bg-white rounded-md p-[30px]">
      <img className="w-full" src={`/Images/${image}`} alt="tag" />
      <div className="flex items-center mt-[20px]">
        <h1 className="text-[#929292] text-[18px] w-full font-bold">{name}</h1>
        <img className="w-8 h-8" src={`/Images/${i}`} alt="i" />
      </div>
      <div className="flex text-[#727272] items-center text-justify mt-[10px] mb-[20px]">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
          voluptatem neque eveniet sed ducimus voluptates iure asperiores quae
          dignissimos ad?
        </p>
      </div>
      <div className="flex justify-between text-[12px] lg:text-[11px] xl:text-[14px] items-center">
        <div className="bg-[#00A3FF] cursor-pointer rounded-3xl flex items-center justify-between text-white lg:px-3 px-5 xl:px- py-2">
          <h1>View Link</h1>
          <img className="w-3 h-3 ml-7" src="/Images/link.png" alt="link" />
        </div>
        <div className="flex space-x-[6px]">
          <h1 className="bg-red-600 cursor-pointer px-1.5 text-white rounded-3xl py-1 text-sm">
              Reject
          </h1>
          <h1 className="bg-green-600 cursor-pointer px-1.5 text-white rounded-3xl py-1 text-sm">
            Accept
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Posted;
