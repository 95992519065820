import {useState,useEffect} from 'react';
import { useGoogleLogin } from "@react-oauth/google";
import YouTubeChannelInfo from './youtube';
const LoginWithGoogleTest = ({ onSuccess }) => {
    const [accessToken,setAccessToken]=useState(null);
    useEffect(() => {
        loginGoogle();
      }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps
    const loginGoogle = useGoogleLogin({
        onSuccess: tokenResp => {
          console.log(tokenResp)
          if(tokenResp!==undefined)
          {
            console.log(tokenResp)
            setAccessToken(tokenResp.access_token)
          }
        },
      });
  return (
    <div
            className="flex justify-center mt-10"
          >
            <YouTubeChannelInfo accessToken={accessToken}/>
          </div>
  );
};

export default LoginWithGoogleTest;
