import React, { useEffect, useState } from "react";
import Profile from "./Profile";
// import axios from "axios";
// import { BASE_URL, PATH } from "../Api";
function Marketplace() {
  // const [count, setCount] = useState(0);
  // const [data, setData] = useState([]);
  const [value,onChange]=useState(0);
  useEffect(()=>{
    const ele = document.querySelector('.buble');
  if (ele) {
    ele.style.left = `${Number(value / 100000)}px`;
  }
})
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between w-full md:space-x-[20px]">
        <div className="border rounded-3xl bg-white flex items-center xl:w-[60%] md:w-[40%]">
          <input
            className="p-2 w-full pl-[20px] rounded-3xl focus:outline-none"
            type="text"
            placeholder="Search Your Favourite Influencers"
          />
          <img
            className="mr-[20px] cursor-pointer h-4 w-4"
            src="/Images/search.png"
            alt=""
          />
        </div>
        <div className="flex mt-[20px]  md:mt-0 space-x-[4px] justify-between text-[#b2aeae] items-center md:w-[70%]">
          <h1 className="lg:text-sm xl:text-lg text-xs">No. of Followers</h1>
          <div className="slider-parent relative">
      <input type="range" min="0" max="10000000" value={value} className="slider w-[100%] h-[5px] rounded-full bg-[#D9D9D9] outline-none"
         onChange={({ target: { value: radius } }) => {
                    onChange(radius);
                  }}
      />
      <div className="buble absolute"> 
      {value}
      </div>
    </div>
          <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px]">
            <select className="w-full rounded-full py-2 pr-1 focus:outline-none">
              <option>Categories</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </select>
          </div>
          <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] text-left">
            <select className="w-full rounded-full py-2 pr-1 focus:outline-none text-left">
              <option className="text-left">Select City</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Delhi">Delhi</option>
              <option value="Punjab">Punjab</option>
            </select>
          </div>
        </div>
      </div>
      <div className="grid md:mt-[40px] mt-[20px] flex-col xl:grid-cols-4 lg:grid-cols-3 lg:gap-6 md:grid-cols-3 md:gap-6 grid-cols-1 gap-3 8k:grid-cols-5 8k:gap-4 4k:grid-cols-4 4k:gap-4 2xl:grid-cols-3 2xl:gap-[0.5vw]">
        {
        //   data?.map((item)=>{
        //     return <>
        //   <Profile image={item.image} id={3454345} i={"50.png"} />
        //     </>

        //  })
        
        }
      
        <Profile image={"p2.png"} id={3454345} i={"300.png"} />
        <Profile image={"p1.png"} id={3454345} i={"100.png"} />
        <Profile image={"p1.png"} id={3454345} i={"300.png"} />
        <Profile image={"p2.png"} id={3454345} i={"700.png"} />
        <Profile image={"p3.png"} id={3454345} i={"300.png"} />
        <Profile image={"p1.png"} id={3454345} i={"100.png"} />
        <Profile image={"p2.png"} id={3454345} i={"700.png"} />
        <Profile image={"p1.png"} id={3454345} i={"50.png"} />
        <Profile image={"p3.png"} id={3454345} i={"300.png"} />
        <Profile image={"p1.png"} id={3454345} i={"50.png"} />
        <Profile image={"p2.png"} id={3454345} i={"700.png"} />
        <Profile image={"p1.png"} id={3454345} i={"50.png"} />
        <Profile image={"p3.png"} id={3454345} i={"700.png"} />
        <Profile image={"p1.png"} id={3454345} i={"100.png"} />
        <Profile image={"p2.png"} id={3454345} i={"700.png"} />
        <Profile image={"p1.png"} id={3454345} i={"50.png"} /> 
      </div>
    </div>
  );
}

export default Marketplace;
