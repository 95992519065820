import React, { useState,useEffect } from "react";
import { useNavigate,createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginError, loginStart, loginSuccess } from "../redux/Slice/userSlice";
import axios from "axios";
import toast from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie"; 
// import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyA7CaTxS0Ciuhqv-Whqf6OHV_gOcit633c",
//   authDomain: "vsal-6d082.firebaseapp.com",
//   projectId: "vsal-6d082",
//   storageBucket: "vsal-6d082.appspot.com",
//   messagingSenderId: "226150467277",
//   appId: "1:226150467277:web:94affbb9e4e4b36cb08b84",
//   measurementId: "G-VPPV320NSR",
// };

// const app = initializeApp(firebaseConfig);

// const auth = getAuth(app);
// const provider = new GoogleAuthProvider();



function Login() {
  const [tokenl, setTokenl] = useState([]);
  useEffect(() => {
    loginGoogleUser();
  }, [tokenl]); // eslint-disable-line react-hooks/exhaustive-deps
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const flag=1;
  const loginUser = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const { data } = await axios.post(
        "https://collabb-backend.onrender.com/api/auth/login",
        {
          username,
          password,
        }
      );
      console.log(data);
      // Cookies.set("token",data.token);
      localStorage.setItem("token", data.token);
      Cookies.set("username",data.username,{ expires: 30, path: '/' })
      Cookies.set("name",data.name,{ expires: 30, path: '/' })
      Cookies.set("token",data.token,{ expires: 30, path: '/' })
      console.log("token ",data.token);
      dispatch(loginSuccess(data));
      setUsername("");
      setPassword("");
      navigate("/");
      toast.success("Login successfully ");
    } catch (err) {
      dispatch(loginError());
      console.log(err?.response?.data?.message);
      toast.error(err?.response?.data?.message);
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: tokenResp => {
      console.log(tokenResp)
      if(tokenResp!==undefined)
      {
      setTokenl(tokenResp);
      }
    },
  });

  const loginGoogleUser = async (e) => {
    try {
      const { data } = await axios.post(
        "https://collabb-backend.onrender.com/api/auth/googleAuth",
        {
          accessToken:tokenl.access_token,
          scope:tokenl.scope,
          tokenType:tokenl.tokenType,
          flag,
        }
      );
      if(data.message==="No such Business User found")
      {
        console.log(data);
        navigate({pathname:"/googleRegister",search:createSearchParams({accessToken:tokenl.access_token,flag:flag}).toString()})
      }
      else
      {
      console.log(data);
      Cookies.set("username",data.username,{ expires: 30, path: '/' })
      Cookies.set("name",data.name,{ expires: 30, path: '/' })
      Cookies.set("token",data.token,{ expires: 30, path: '/' })
      // Cookies.set("token",data.token);
      localStorage.setItem("token", data.token);
      console.log("token ",data.token)
      navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const loginGoogleUser = async (e) => {
  //   e.preventDefault();
  //   dispatch(loginStart());
  //   try {
  //     const { data } = await axios.post(
  //       "https://collabb-backend.onrender.com/api/auth/googleAuth",
  //       {
  //         tokenResponse
  //       }
  //     );
  //     console.log(data);
  //     // Cookies.set("token",data.token);
  //     localStorage.setItem("token", data.token);
  //     dispatch(loginSuccess(data));
  //     setUsername("");
  //     setPassword("");
  //     navigate("/");
  //     toast.success("Login successfully ");
  //   } catch (err) {
  //     dispatch(loginError());
  //     console.log(err?.response?.data?.message);
  //     toast.error(err?.response?.data?.message);
  //   }
  // };

  

  return (
    <div className="flex lg:h-screen h-[calc(100vh-100px)] mobile:h-[100vh]">
      <div className="bg-blue-500 lg:flex hidden items-center justify-center basis-[50%]">
        <img
          className="lg:w-7vh h-7vh  md:w-2vh h-2vh  4k:h-[30vh]"
          src={"/Images/Welcome_back.png"}
          alt="logo"
        />
      </div>
      <div className="flex items-center bg-[#F4F4F4] justify-center basis-[100%] lg:basis-[50%]">
        <form
          onSubmit={loginUser}
          className="xl:w-[500px] mx-4 border md:m-0 lg:w-[450px] md:w-[550px] space-y-[20px] text-center bg-white p-[20px]  4k:h-[68%]  4k:w-[75%] "
        >
          <h1 className="font-bold 4k:text-2xl 1440k:text-5xl mobile:text-2xl">
            Welcome back
          </h1>
          <p className="text-[#cbcdcc] 4k:text-xl">
            Get latest news update instantly{" "}
          </p>
          <div
            className="flex justify-center"
            onClick={() => {loginGoogle()}}
          >
            <div className="border cursor-pointer py-[10px] mr-[20px] w-full justify-center p-2 rounded-lg flex items-center 4k:h-[4em] 4k:mt-1 4k:mb-1 ">
              <img
                className="w-9 h-8 mr-[5px] md:mr-0 md:mx-[10px] lg:mx-[10px] 4k:h-11 4K:w-14 "
                src="/Images/google.png"
                alt="google"
              />
              <h1 className="mr-[5px] md:mr-[10px] 4k:text-2xl">
                Continue with Google
              </h1>
            </div>
          </div>

          <div className="4k:pb-2">
            <h1 className="text-[#cbcdcc] 4k:text-2xl">Or with email</h1>
          </div>
          {/* <div className="space-y-[20px]"> */}
          <input
            className="bg-[#F4F4F4] pl-[20px] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg p-3 4k:h-[5vh] 4k:text-2xl"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <div className="flex items-center hover:border hover:border-black rounded-lg  bg-[#F4F4F4] 4k:h-[5vh]">
            <input
              className="bg-[#F4F4F4] w-full pl-[20px] rounded-lg focus:outline-none p-3  4k:text-2xl"
              type={!visible ? "password" : "text"}
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              className="w-5 cursor-pointer h-5 mr-[20px] 4k:h-8 4k:w-9 mobile:h-6 mobile:w-7"
              src={!visible ? "/Images/Vector (1).png" : "/Images/show.png"}
              alt="Vector"
              onClick={() => setVisible(!visible)}
            />
          </div>
          {/* </div> */}
          <div className="">
            <p className=" 4k:text-xl mobile:text-sm">
              By creating an account. you agreeing to our{" "}
              <span className="font-bold">Privacy Policy</span>, and{" "}
              <span className="font-bold">
                Electronics Communication Policy
              </span>
            </p>
          </div>

          <input
            className="bg-blue-500 w-full cursor-pointer rounded-3xl font-bold text-white p-1 4k:h-[4vh] 4k:text-2xl 4k:rounded-full 4k:my-5"
            type="submit"
            value="Sign In"
          />

          <p className="text-[#cbcdcc]  4k:text-2xl">
            Don't have an account?{" "}
            <span
              className="font-bold cursor-pointer text-black"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </span>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
