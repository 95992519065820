import React from "react";
// import { useNavigate } from "react-router-dom";

function TableCamp({ t }) {
  // const platform = (plt) => {
    //   if (plt === "Instagram") {
      //     return `/Images/insta.png`;
      //   } else if (plt === "Facebook") {
        //     return `/Images/facebook.png`;
        //   } else {
          //     return `/Images/youtube.png`;
          //   }
          // };
          // const navigate = useNavigate();
  return (
    <div className="text-[14px] flex md:flex-row flex-col h-fit bg-white rounded-md py-[20px] px-[20px]">
      <div className="md:basis-[45%]">
        <img className="w-[90%] h-full" src="/Images/brand.png" alt="tag" />
      </div>

      <div className="md:basis-[50%] flex md:mt-0 mt-[20px] flex-col justify-start space-y-[10px] mr-[20px]">
        <div className="flex items-center">
          <h1 className="font-extrabold text-black text-[18px] w-full ">
          Cococola Campaign
          </h1>
          <img className="w-9 h-6" src="/Images/youtube.png" alt="i" />
        </div>
        <div className="flex justify-between text-[12px] my-[20px] lg:text-[11px] xl:text-[14px] items-center">
          <h1 className="text-black font-bold text-lg mb-5">Alcohol</h1>
          <div className="flex flex-col">
          <h1 className="text-black font-bold">Campaign Type</h1>
          <h1 className="text-right text-[#727272] font-bold text-[16px]">Post</h1>
          </div>
        </div>

        <div>
          <h1 className="text-[16px] font-bold">Description</h1>
          <p className="text-[#727272] text-[13px]">
            {/* {t?.description?.length > 90
              ? t?.description.substring(0, 180) + "..."
              : t?.description} */}
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, ut?
            Aspernatur placeat consequatur molestiae est illo, reprehenderit
            maiores ad similique minima, sequi impedit ratione inventore!
          </p>
        </div>

        <div>
          <h1 className="text-[16px] font-bold">Instructions for influencers</h1>
          <p className="text-[#727272] text-[12px]">
            {/* {t?.instruction?.length > 90
              ? t?.instruction.substring(0, 180) + "..."
            : t?.instruction} */}
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, ut?
            Aspernatur placeat consequatur molestiae est illo, reprehenderit
          </p>
        </div>
        {/* visit row  */}
        <div className="flex justify-between">
            <h1 className="text-[16px] font-extrabold text-black">CPM : NAN</h1>


        <div className="bg-[#00A3FF] space-x-14 cursor-pointer rounded-3xl flex items-center justify-between text-white px-4 py-2 8k:text-[1vw] 3xl:text-[1vw] 4k:text-[1.3vw]"
         onClick={() => {
        }}
        >
        <h1 className="2xl:px-[10px]">View Link</h1>
        <img className="w-4 h-4  8k:w-[1vw] 8k:h-[1vw] 4k:w-[1vw] 4k:h-[1vw] 3xl:w-[1vw] 3xl:h-[1vw] " src="/Images/link.png" alt="link" />
      </div>
        </div>
      </div>
    </div>
  );
}

export default TableCamp;
