import React from "react";
import { useNavigate } from "react-router-dom";

function Profile({ image, i, id }) {
  const navigate = useNavigate();

  return (
    <div className="2xl:w-[12vw] md:w-[225px] w-full h-fit bg-white rounded-md p-[10px] 8k:w-[10vw] 4k:w-[15vw] 3xl:w-[12vw]">
      <div className="relative group">
        <img
          className="h-full w-full cursor-pointer "
          src={`/Images/${image}`}
          alt="pro"
          onClick={() => {
            navigate(`/single/${id}`);
            localStorage.setItem('imagelink','marketplace_influencer.png');
          }}
        />
        <img
          className=" absolute -bottom-4 -right-3"
          src={`/Images/${i}`}
          alt="tag"
        />
      </div>
      <div className="flex justify-between mt-[20px] ">
        <div className="flex flex-col ">
          <h1 className="text-[#727272] text-xs mb-[5px] 3xl:text-[0.8vw] 8k:text-[0.7vw] 4k:text-[0.8vw]">Engagement Rate</h1>
          <h1 className="font-bold mb-[10px] 3xl:text-[0.8vw] 8k:text-[0.8vw] 4k:text-[1vw]">20%</h1>
        </div>
        <div className="flex flex-col items-end">
          <h1 className="text-[#727272] text-xs mb-[5px] 3xl:text-[0.8vw] 8k:text-[0.6vw] 4k:text-[0.7vw]">Plateform</h1>
          <img className="w-6 h-6 8k:w-[1.5vw] 8k:h-[1.5vw]" src="/Images/inst.png" alt="media" />
        </div>
      </div>
      <div className="flex items-center mb-[10px]">
        <img className="w-3 h-3 mr-[2px] 3xl:w-[1vw] 3xl:h-[1vw] 8k:w-[1vw] 8k:h-[1vw] 4k:w-[1vw] 4k:h-[1vw]" src="/Images/star.png" alt="star" />
        <img className="w-3 h-3 3xl:w-[1vw] 3xl:h-[1vw] 8k:w-[1vw] 8k:h-[1vw] 4k:w-[1vw] 4k:h-[1vw]" src="/Images/sta.png" alt="sta" />
      </div>
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-sm 3xl:text-[0.7vw] 8k:text-[0.7vw] 4k:text-[0.8vw]">Entertainment</h1>
        <h1 className="flex text-xs text-[#727272] 3xl:text-[0.8vw] 8k:text-[0.8vw] 4k:text-[0.8vw]">
          Price <pre> </pre><span className="text-black font-extrabold">$3000</span>
        </h1>
      </div>
    </div>
  );
}

export default Profile;
