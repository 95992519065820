import React from "react";

function Wallet() {
  return (
    <div className="text-green-500 font-Poppins text-5xl flex justify-center items-center h-screen w-screen">
      Payment successfully done
    </div>
  );
}

export default Wallet;
