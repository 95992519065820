import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';
const InstagramTest = () => {
  const [statistics, setStatistics] = useState(null);
  const [accessToken, setAccessToken] = useState('');

  const handleFacebookResponse = (response) => {
    if (response.accessToken) {
      const { accessToken, userID } = response;
      console.log(response)
      // Call your backend API to handle the Facebook login
      fetch(`http://localhost:3001/api/instagram/login-with-facebook?accessToken=${accessToken}&userID=${userID}`)
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from the backend
          console.log(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      console.log('User canceled login or did not authorize the app.');
    }
  };

  const fetchStatistics = async () => {
    try {
      console.log("fetchStatistics",fetchStatistics)
      const response = await axios.get(`http://localhost:3001/api/instagram/api/user/${accessToken}/statistics`);
      console.log("response ",response.data)
      setStatistics(response.data);
    } catch (error) {
      console.error('Error:', error.response.data);
    }
  };

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const authorizationCode = urlParams.get('code');

        // Make a request to your backend to retrieve the access token
        if(authorizationCode)
        {
        const response = await axios.get('http://localhost:3001/api/instagram/auth/instagram/callback?code='+authorizationCode);
        console.log(response.data)
        setAccessToken(response.data);
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    };

    fetchAccessToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accessToken) {
      fetchStatistics();
    }
  }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = () => {
    // Redirect the user to the authorization URL to sign in and grant permissions
    window.location.href = 'http://localhost:3001/api/instagram/auth/instagram';
  };

  if (!accessToken) {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <h1>Access token not found</h1>
        <button onClick={handleLogin}>Login</button>
        <FacebookLogin
        appId="922071988899035"
        autoLoad={false}
        fields="name,email"
        scope="instagram_basic,pages_show_list"
        callback={handleFacebookResponse}
      />
      </div>
    );
  }

  if (!statistics) {
    return <h1 style={{ backgroundColor: 'white' }}>Loading...</h1>;
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <h1>Dashboard</h1>
      <h2>Followers: {statistics.followers_count}</h2>
      <h2>Following: {statistics.follows_count}</h2>
      <h2>Total Likes: {statistics.totalLikes}</h2>
      <h2>Total Comments: {statistics.totalComments}</h2>
    </div>
  );
};

export default InstagramTest;
