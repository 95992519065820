import React, { useEffect, useState } from "react";

function SinglePage() {
  const[image,setImage]=useState(null);
  useEffect(()=>{
    setImage(localStorage.getItem('imagelink'))
  },[])
  return (
    <div className="p-[20px] w-full md:p-2  lg:h-[calc(100vh-102px)] 8k:overflow-y-auto h-auto">
      <div className="md:flex md:space-y-0 space-y-[10px] gap-[10px] 8k:w-[100%] 8k:space-x-0 4k:w-[100%] ">  
        {/* first section  */}
        <div className="flex flex-col items-center 5k:h-[30vw] md:w-[90%] tk:h-[37vw] md:h-[48vw] lg:h-[44vw] bg-white h-fit rounded-lg  8k:p-5 8k:w-screen 8k:h-fit 4k:h-[30vw] 3xl:h-[34vw] rk:h-[32vw] 1440k:h-[43vw] lgk:h-[51vw] ">
          <div className="flex flex-row px-[10px] pt-[20px] 8k:gap-6 1440k:gap-4">
            <img
              className=" "
              src={`/Images/${image}`}
              alt="integrate"
            /> 
          </div>
          <div className="space-y-[10px]">
            <h1 className="text-[1.3em] my-[10px] text-center 8k:text-3xl 8k:font-bold font-extrabold">
              Gardening, cooking, and cocktails by @sow.to.stove
            </h1>
            <div className="xl:flex justify-between px-[10px] 8k:pt-3">
              <div className="flex space-x-[10px]">
                <h1 className="text-blue-400 8k:text-2xl text-sm">Influencer Marketing</h1>
                <div classname='border border-left-0'/>
                <h1 className="text-blue-400 8k:text-2xl text-sm">Instagram Reels</h1>
              </div>
              <div className="flex space-x-[10px] items-center">
                <h1 className="font-bold 8k:text-2xl">Entertainment </h1>
                <div className="flex">
                  <img className="w-3 h-3 8k:w-6 8k:h-6" src="/Images/star.png" alt="star" />
                  <img className="w-3 h-3 8k:w-6 8k:h-6" src="/Images/star.png" alt="star" />
                  <img className="w-3 h-3 8k:w-6 8k:h-6" src="/Images/star.png" alt="star" />
                  <img className="w-3 h-3 8k:w-6 8k:h-6" src="/Images/sta.png" alt="star" />
                  <img className="w-3 h-3 8k:w-6 8k:h-6" src="/Images/sta.png" alt="star" />
                </div>
                <img className="w-5 h-5" src="/Images/insta.png" alt="insta" />
              </div>
            </div>
            <p className="px-[10px] text-[#495057] pb-[10px] 8k:text-xl font-Inter text-md font-normal">
              I'll feature your product in an Instagram Reel or post on
              @sow.to.stove by demonstrating or using it. I generally post about
              gardening and cooking. My audience is 78% females, mostly ages
              25-34.
            </p>
          </div>
        </div>
        {/* second right section  */}
        <div className="bg-white md:w-1/2 p-[20px]  rounded-lg">
          <div className="text-bold border border-x-0 border-t-0 pb-[10px] 8k:text-3xl 8k:font-bold">
            Listing Overview
          </div>
          <div className="flex border border-x-0 border-t-0 text-[#495057] space-y-[10px] flex-col pt-[20px] pb-5">
            <div className="flex justify-between 8k:text-xl 4k:text-[1vw]">
              <div className="flex flex-col space-y-[5px]">
                <div>DEAL</div>
                <div className="font-bold text-black text-sm">Sponsored Instagram Reels Video</div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row text-sm"><img className="object-scale-down h-5" src="/Images/cali.png" alt="calendar" height="2px"></img>
                    <pre> </pre>15 days Delivery
                  </div>
                  <div className="flex flex-row text-sm"><img className="object-scale-down h-5" src="/Images/C_vector.png" alt="calendar" height="2px"></img>
                    <pre> </pre>Content Rights
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-row text-sm"><img className="object-scale-down h-5" src="/Images/shield.png" alt="calendar" height="2px"></img>
                    <pre> </pre>Satisfaction Guaranteed
                  </div>
                </div>
              </div>
              <div className="font-bold text-black pt-6">Rs 50</div>
            </div>
          </div>
          <div className="flex text-[#495057] space-y-[10px] flex-col pt-[10px]">
            <div className="flex justify-between 8k:text-xl 4k:text-[1vw] font-bold text-black">
              <div>Subtotal</div>
              <div>Rs 50</div>
            </div>
            <div className="flex justify-between 8k:text-xl 4k:text-[1vw] font-bold text-black">
              <div>Total Delivery Time</div>
              <div className="flex items-center">
                <img
                  className="w-5 h-5 mr-2"
                  src="/Images/cali.png"
                  alt="calender"
                />
                <h1>15 days</h1>
              </div>
            </div>
          </div>
          <div className="bg-[#00A3FF] text-center cursor-pointer my-[20px] rounded-lg py-[10px] px-[10px] text-white 8k:text-2xl 8k:mt-9">
            Get Influencer
          </div>
          <p className="lgk:text-[0.1vw] text-xs">
          <span className="font-bold">
            You won't be charged until after you click{" "}
            <span className="text-blue-500 text-xs">'Get Influencer'</span> .</span>
            <span className="text-[#6C757D] text-xs">If the seller doesn't respond in 7 days, the order will be
            automatically cancelled and your payment will be refunded.</span>
          </p>
        </div>
      </div>

      <div className="flex lg:justify-start justify-between lg:w-[85%] mt-[40px] mb-[10px] 8k:justify-between 8k:justify-between 1440k:justify-between 1440k:pb-2  3xl:justify-between  4k:justify-between rk:justify-between lgk:justify-between">
        <h1 className="font-extrabold 8k:text-3xl 4k:text-[1.5vw] 3xl:text-[1.4vw]">
          <span>About</span> @sow.to.stove
        </h1>
        <img
          className="w-5 h-5 lg:ml-[20px] 8k:w-9 8k:h-9 3xl:w-[1.5vw] 3xl:h-[1.5vw]  4k:w-[1.5vw] 4k:h-[1.5vw]  "
          src="/Images/insta.png"
          alt="logo"  
        />
      </div>
      <div className="grid lg:w-[85%] xl:grid-cols-3 gap-[10px] lg:grid-cols-2 grid-cols-1 8k:mt-9">
        <div className="2xl:w-[200px] xl:w-[190px] lg:w-[255px] space-y-[10px] rounded-lg bg-white p-[20px] 8k:w-[25vh] 1440k:w-[20vw] 4k:w-[20vw] 3xl:w-[15vw] 3xl:text-[1.2vw] rk:w-[20vw]">
          <h1 className="text-[#6C757D] 8k:text-3xl 1440k:text-xl  ">Followers</h1>
          <h1 className="8k:text-3xl 1440k:text-xl">3.3k</h1>
        </div>

        <div className="2xl:w-[200px] xl:w-[190px] lg:w-[255px] space-y-[10px] rounded-lg bg-white p-[20px] 8k:w-[25vh] 1440k:w-[20vw] 4k:w-[20vw] 3xl:w-[15vw] 3xl:text-[1.2vw] rk:w-[20vw] ">
          <h1 className="text-[#6C757D] 8k:text-3xl 1440k:text-xl ">Avg. Eng. Per Post</h1>
          <h1 className="8k:text-3xl 1440k:text-xl">3.3k</h1>
        </div>

        <div className="2xl:w-[200px] xl:w-[190px] lg:w-[255px] space-y-[10px] rounded-lg bg-white p-[20px] 8k:w-[25vh] 1440k:w-[20vw] 4k:w-[20vw] 3xl:w-[15vw] 3xl:text-[1.2vw] rk:w-[20vw]">
          <h1 className="text-[#6C757D] 8k:text-3xl 1440k:text-xl ">Eng. Rate</h1>
          <h1 className="8k:text-3xl 1440k:text-xl">3.3k</h1>
        </div>
      </div>

      <h1 className="font-extrabold mt-[20px] mb-[10px] 8k:text-3xl 8k:mt-9 8k:mb-8  4k:text-[1.5vw] 3xl:text-[1.4vw] 4k:pt-[1vw]">Choose Your Package !</h1>
      <div className="grid xl:grid-cols-3 justify-center items-center w-full gap-[20px] lg:grid-cols-2  8k:mb-9">
        {/* 1 start  */}
        <div className="2xl:w-[260px] xl:w-[290px] w-[350px] flex flex-col h-full justify-between p-[20px] rounded-lg bg-white 8k:w-[30vh] 4k:w-[20vw]  3xl:w-[80%]">
          <h1 className="text-[32px] mb-[10px] 8k:text-5xl 1440k:text-3xl">Starter</h1>
          <div className="flex justify-between mb-[20px] items-center">
            <h1 className="8k:text-2xl">
              $10.99/<span className="text-[#ACB4C3]">Month</span>
            </h1>
            <div className="text-blue-500 rounded-2xl px-3 py-1 bg-[#F0F5FF] 8k:text-2xl">
              popular
            </div>
          </div>

          <div className="flex text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1 className="8k:text-2xl">Reels</h1>
          </div>
          <div className="flex my-[20px] text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2 8k:w-9 8k:h-9"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Stories</h1>
          </div>
          <div className="flex text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Priority Support</h1>
          </div>
          <div className="border my-[40px]"></div>
          <div className="border text-center mb-[20px] py-3 rounded-lg 8k:text-3xl ">
            Get Influencer
          </div>
        </div>
{/* 2 cards */}
        <div className="2xl:w-[260px] xl:w-[290px] w-[350px] flex flex-col h-full justify-between p-[20px] rounded-lg text-white bg-[#00A3FF] 8k:w-[30vh] 4k:w-[20vw] 3xl:w-[80%]">
          <h1 className="text-[32px] mb-[10px] 8k:text-5xl 1440k:text-3xl">Best Valued</h1>
          <div className="mb-[20px]">
            <h1  className="8k:text-2xl">
              $50.99/<span  className="8k:text-2xl">Month</span>
            </h1>
          </div>

          <div className="flex">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkboxfill.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Reels</h1>
          </div>
          <div className="flex mt-[20px]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkboxfill.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Stories</h1>
          </div>
          <div className="flex my-[20px]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkboxfill.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Video</h1>
          </div>
          <div className="flex">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkboxfill.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Priority Support</h1>
          </div>
          <div className="border my-[40px]"></div>
          <div className="border text-center bg-white text-black mb-[20px] py-3 rounded-lg 8k:text-3xl">
            Get Influencer
          </div>
        </div>
{/* 3 card  */}
        <div className="2xl:w-[260px] xl:w-[290px] w-[350px] flex flex-col h-full justify-between p-[20px] rounded-lg bg-white 8k:w-[30vh] 4k:w-[20vw] 3xl:w-[80%]">
          <h1 className="text-[32px] mb-[10px] 8k:text-5xl 1440k:text-3xl 4k:text-3xl">Professional</h1>
          <div className="flex justify-between mb-[20px] items-center">
            <h1  className="8k:text-2xl">
              $100.99/<span className="text-[#ACB4C3] 8k:text-2xl">Month</span>
            </h1>
            <div className="text-blue-500 rounded-2xl px-3 py-1 bg-[#F0F5FF] 8k:text-2xl">
              popular
            </div>
          </div>

          <div className="flex text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Reels</h1>
          </div>
          <div className="flex mt-[20px] text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Stories</h1>
          </div>
          <div className="flex my-[20px] text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Video</h1>
          </div>
          <div className="flex text-[#ACB4C3]">
            <img
              className="w-6 h-6 mr-2"
              src="/Images/checkbox.png"
              alt="checkbox"
            />
            <h1  className="8k:text-2xl">Priority Support</h1>
          </div>
          <div className="border my-[40px]"></div>
          <div className="border text-center mb-[20px] py-3 rounded-lg 8k:text-3xl">
            Get Influencer
          </div>
        </div>
      </div>
      {/* <div className="">sdkjhf</div> */}
    </div>
  );
}

export default SinglePage;
