import React, { useEffect, useState } from "react";
import axios from "axios";
// import Cookies from "js-cookie";
// import { useSelector } from "react-redux";
function Wallet() {
  // const { currentUser } = useSelector((state) => state.user);
  const [visible, setVisible] = useState(false);
  const [money, setMoney] = useState("");
  // const current = currentUser?.others ? currentUser?.others : currentUser;

  const [wallet, setWallet] = useState("");
  useEffect(() => {
    const getWallet = async () => {
      try {
        const { data } = await axios.get(
          "https://backend-vsal.onrender.com/api/wallet",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        );
        console.log(data);
        setWallet(data);
      } catch (error) {
        console.log(error);
      }
    };
    getWallet();
  }, []);

  const checkoutHandler = async (amount) => {
    const data1 = await axios.get("https://backend-vsal.onrender.com/api/getkey");

    const { data } = await axios.post(
      "https://backend-vsal.onrender.com/api/payment/checkout",
      {
        amount,
      }
    );

    const options = {
      key: data1?.data?.key,
      amount: data.amount,
      currency: "INR",
      name: "Vsal",
      description: "Wallet payment",
      image: "",
      order_id: data.id,
      // callback_url: `https://backend-vsal.onrender.com/api/payment/verification/${data.amount}/${wallet._id}/${current._id}`,
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#528FF0",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <div className="my-[50px] flex items-center justify-center  h-[calc(100vh-60px)] xl:w-[70%] w-full lg:h-[calc(100vh-105px)] sm:my-[50px] md:my-[50px] mobile:my-[30px] mobile:flex mobile:items-center mobile:justify-center">
      {!visible ? (
        <div className="space-y-[20px] h-[100%] md:basis-[50%] mr-[20px] md:space-y-[20px]">
          {/* single row  */}
          <div className="flex flex-row items-center justify-center gap-5 w-[100%] 8k:ml-[10vw] 3xl:w-[50vw] 4k:w-[50vw] rk:w-[50vw] rk:ml-[7vw] 1440k:w-[50vw] 1440k:ml-[7vw] lgk:w-[50vw] lgk:ml-[7vw]" >

          {/* first row  */}
          <div className=" flex flex-col gap-5 ml-5 w-[70%] 8k:w-[10vw] sm:w-[40vw] md:w-[40vw] lgk:gap-4 ">
          <div className="bg-white rounded-lg px-[20px] py-[20px] 8k:h-[9vw]  3xl:h-[8vw] 4k:h-[8vw] 1440k:h-[12vw] lgk:h-[13vw] ">
            <div className="flex justify-between items-center">
              <h1 className="text-[#b2aeae]">Total Balance</h1>
              <img className="w-1 " src="/Images/ham2.png" alt="ham2" />
            </div>

            <div className="flex justify-between items-center">
              <h1 className="text-black text-[24px] font-bold 1440k:text-[1.4vw]">
                Rs {wallet?wallet.money:'0'}
              </h1>
              <img className="w-5 " src="/Images/eyeslash.png" alt="ham2" />
            </div>

            <div className="flex my-[10px]">
              <div className="border cursor-pointer rounded-lg mr-[20px] border-blue-300 text-blue-300 justify-between flex items-center px-3 py-2 4k:h-[2vw]  lgk:h-[4vw] ">
                <img
                  className="w-4 h-4 mr-[10px] 1440k:w-[1vw] 1440k:h-[1vw] lgk:w-[1vw] lgk:h-[1vw]"
                  src="/Images/send.png"
                  alt="send"
                />
                <h1 className="1440k:text-[1vw]  lgk:text-[1vw]">Send Money</h1>
              </div>
              <div className="rounded-lg cursor-pointer  bg-blue-400 justify-between text-white flex items-center px-3 py-2 4k:h-[2vw] lgk:h-[4vw] ">
                <img
                  className="w-6 h-6 mr-[10px] lgk:w-[1.7vw] lgk:h-[1.7vw]"
                  src="/Images/add.png"
                  alt="send"
                />
                <h1
                className="1440k:text-[1vw] lgk:text-[1vw]"
                  /* onClick={() => checkoutHandler(400)}*/ onClick={() =>
                    setVisible(true)
                  }
                >
                  Add Money
                </h1>
              </div>
            </div>
          </div>
          {/* total spend  */}
          <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw]">
            <div className="flex items-center">
              <img
                className="w-[25%] h-[70%] mr-[20px] 4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw]"
                src="/Images/rs.png"
                alt="rs"
              />
              <div className="w-[70%] flex flex-col">
                <h1 className="text-[#b2aeae] text-[14px]">Total Spend</h1>
                <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]"> 
                  Rs {wallet?wallet.totalSpend:'0'}

                </h1>
              </div>
            </div>
            <img
              className="w-6 h-6"
              src="/Images/arrowright2.png"
              alt="arrowRight"
            />
          </div>
          {/* total spend  */}
          <div className="bg-white px-[20px] justify-between flex py-[10px] rounded-lg items-center 8k:h-[4vw] 3xl:h-[4vw] 4k:h-[4vw] 1440k:h-[5vw] lgk:h-[6vw]">
            <div className="flex items-center">
              <img
                className="w-[25%] h-[70%] mr-[20px]  4k:h-[3vw] 4k:w-[3vw] 1440k:w-[3vw] 1440k:h-[3vw]"
                src="/Images/rs.png"
                alt="rs"
              />
              <div className="w-[70%] flex flex-col">
                <h1 className="text-[#b2aeae] text-[14px]">Total Spend</h1>
                <h1 className="font-bold text-[22px]  1440k:text-[1.4vw]  lgk:text-[1.4vw]"> 
                  Rs {wallet?wallet.totalSpend:'0'}

                </h1>
              </div>
            </div>
            <img
              className="w-6 h-6"
              src="/Images/arrowright2.png"
              alt="arrowRight"
            />
          </div>
</div>
{/* second section  image*/}
<div className=" hidden  lg:block">
<div className="lg: bg-white rounded-lg flex items-center justify-center w-[100%] p-1 md:block 8k:h-[19vw] 8k:w-[20vw] 8k:flex  3xl:flex  3xl:w-[20vw] 3xl:h-[18vw] 4k:flex 4k:w-[20vw] 4k:h-[18vw] rk:flex rk:w-[25vw] rk:h-[26vw]  1440k:flex 1440k:w-[25vw] 1440k:h-[25vw] lgk:flex lgk:w-[25vw] lgk:h-[28vw]">

<img
          className="items-center lg:w-[500px] h-[350px] md:w-[150px] 8k:h-[8vw] 8k:w-[12vw] 4k:h-[8vw] 4k:w-[10vw] 3xl:h-[8vw] 3xl:w-[10vw]  1440k:w-[12vw] 1440k:h-[10vw] lgk:w-[12vw] lgk:h-[10vw] "
          src={"/Images/wallet_frame.png"}
          alt="logo"
        />
  </div>
  </div>

</div>
{/* table  */}
<div className="rounded-lg bg-white relative group py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full ">
{/* <div className="rounded-lg bg-white py-[10px] w-[100%] p-3 overflow-x-scroll md:w-full 8k:w-[60vw] sm:w-full md:w-[70vw] "> */}
<div className="flex flex-row justify-between">
  <div>
    <h1 className="text-[#455468] font-bold text-lg p-8 ml-5">Transactions</h1>
  </div>
  <div className="p-5 space-x-2">
    <button className="border-box xl:text-md font-semibold text-[#455468] lg:text-base md:text-sm max-sm:text-xs xs:text-xs lg:px-4 lg:py-2 xl:px-4 xl:py-2 bg-white rounded-lg border border-solid border-[#D7DFE9] md:px-3 md:py-2 sm:px-2 sm:py-1">
      <div className="flex flex-row justify-between">
      <img src="/Images/Vectorbox_filter.png" alt="vector"className="h-5 pr-4"/>
      <h1>Filter</h1>
      </div>
    </button>
    <button className="border-box font-semibold text-[#455468] xl:text-md lg:text-base md:text-sm max-sm:text-xs xs:text-xs lg:px-4 lg:py-2 xl:px-4 xl:py-2 bg-white rounded-lg border border-solid border-[#D7DFE9] md:px-3 md:py-2 sm:px-2 sm:py-1">
    <div className="flex flex-row justify-between">
    <img src="/Images/Vectorbox_filter.png" alt="vector"className="h-5 pr-4"/>
    <h1>Search</h1>
    </div>
    </button>
  </div>
</div>        
<table className="table-auto  md:gap-2 text-center lg:w-[95%] rk:w-[60vw]  1440k:w-[60vw] lgk:w-[60vw] md:overflow-x-auto">
          <thead className="text-xs">
            <tr className="text-[#8897AE] font-normal">
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">Order no.</th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"><img className="inline-block pr-1" src="/Images/Iconcalendar_31.png" alt="calendar"/>Date</th>
              <th className="text-xs  pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"><img className="inline-block pr-1" src="/Images/Spinnerspinner.png" alt="spinner"/>State</th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"><img className="inline-block pr-1" src="/Images/CurrencyDollardollar.png" alt="dollar"/>Total Price</th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]"><img className="inline-block pr-1" src="/Images/Vectorlocation_vector.png" alt="location"/>Location</th>
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] md:w-[70vw] ">
            {/* {info.map((i) => ( */}

            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468]  font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            <tr className="">
              <td className="text-xs pt-[20px] md:text-lg">
                <div className="flex justify-center items-center">
                 
                  <h1 className="text-[#455468] font-Inter font-normal 8k:text-2xl 3xl:text-[1vw]">DL - 19266755</h1>
                </div>
              </td> 
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              Feb 28, 2023
              </td>
              <td className=" flex items-center justify-center text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
              <img className="w-fit h-fit " src="/Images/credit.png" alt="ham2" />

              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              $21,000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#5E718D] font-semibold">
              285 Great North Road,<br/> Grey Lynn.
              </td>
              
            </tr>
            

           

            {/* ))} */}
          </tbody>
        </table>
      </div>
        </div>
      ) : (
        <div className="space-y-[20px] h-[60%] md:basis-[50%] px-2 mr-[20px] ">
          <form className="bg-white px-[20px] space-y-[20px] justify-between flex-col flex py-[20px] rounded-lg ">
            <input
              className="bg-[#F4F4F4] pl-[20px] w-full hover:border hover:border-black focus:outline-none focus:border focus:border-black rounded-lg  p-3"
              type="text"
              placeholder="Enter money to be imported"
              value={money}
              onChange={(e) => setMoney(e.target.value)}
              required
            />
            <input
              type="button"
              onClick={() => checkoutHandler(money)}
              value="Import"
              className="bg-blue-500 w-1/2 cursor-pointer rounded-3xl font-bold text-white p-2"
            />
          </form>
        </div>
      )}
    </div>
  );
}

export default Wallet;
