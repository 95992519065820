import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import GoogleRegister from "./Pages/GoogleReg";
import axios from "axios";
import { useSelector } from "react-redux";
import Wallet from "./Pages/Wallet";
import UniqueIdRedirect from "./Pages/UniqueIdRedirect";
import LoginWithGoogleTest from "./Pages/login_test";
import InstagramTest from "./Pages/instagram";
axios.defaults.withCredentials = true;
function App() {
  const { currentUser } = useSelector((state) => state.user);
  const current = currentUser?.others ? currentUser?.others : currentUser;
  return (
    <Routes>
      <Route exact path="/" element={localStorage.getItem('token')!=='' ? <Home /> : <Login />} />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/:id" element={<UniqueIdRedirect />} />
      <Route exact path="/logintest" element={<LoginWithGoogleTest />} />
      <Route exact path="/instagram" element={<InstagramTest />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/googleRegister" element={<GoogleRegister />} />
      <Route exact path="/wallet" element={current ? <Wallet /> : <Login />} />
      <Route exact path="/single/:id1" element={<Home />} />
      <Route exact path="/campaign/:id2" element={<Home />} />
      <Route exact path="/affiliateform/:id3" element={<Home />} /> 
      <Route exact path="/affiliatehome/:id4" element={<Home />} />
      <Route exact path="/affiliate/:id5" element={<Home />} />
    </Routes>
  );
}

export default App;
