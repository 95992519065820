import React from "react";
import Card from "./Card";

function Integration() {
  return (
    <div className="grid 2xl:grid-cols-5 md:mt-[40px] md:items-start xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 md:grid-cols-3 gap-3 overflow-y-scroll md:h-[calc(100vh-141px)] lg:h-[calc(100vh-142px)] 4k:grid-cols-4 8k:grid-cols-5">
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      <Card image={"shopify.png"} title={"Shopify Store"} />
      <Card image={"woocommerce.png"} title={"Woocommerce"} />
      {/* <Card image={"woocommerce.png"} title={"Woocommerce"} /> */}
    </div>
  );
}

export default Integration;
