import React from 'react';
import { useNavigate } from 'react-router-dom';

const AffiliateViewLinkButton = ({ link }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-[#00A3FF] space-x-14 cursor-pointer rounded-3xl flex items-center justify-between text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-blue-900"
      onClick={() => {
        navigate(`/affiliatehome/${link}`);
      }}
    >
      <h1 className="px-2 text-xs md:text-base">View Link</h1>
      <img
        className="w-4 h-4"
        src="/Images/link.png"
        alt="link"
      />
    </div>
  );
};

export default AffiliateViewLinkButton;
