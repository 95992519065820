import React, { useState } from "react";
import toast from "react-hot-toast";
import Cookie from "js-cookie";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function Formaffiliate() {
  // const [micro, setMicro] = useState(false);
  // const [nano, setNano] = useState(true);
  // const [mega, setMega] = useState(false);
  // const [any, setAny] = useState(false);
  const [name, setName] = useState("");
  // const [mid, setMid] = useState(false);
  // const [macro, setMacro] = useState(false);
  // const [post, setPost] = useState(true);
  // const [story, setStory] = useState(false);
  // const [reels, setReels] = useState(false);
  const [category, setCategory] = useState("");
  const [platform, setPlatform] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("post");
  const [summary, setSummary] = useState("");
  const [url, setUrl] = useState("");
  const [requiredInfluencers, setRequiredInfluencers] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cpm, setCpm] = useState("");
  // const [cost, setCost] = useState("");
  // const [visible1, setVisible1] = useState(false);
  // const [visible2, setVisible2] = useState(false);

  const [cover, setCover] = useState("");
  const [sample, setSample] = useState("");
  const [sample1, setSample1] = useState("");
  const [sample2, setSample2] = useState("");
  const [selectedImg1, setSelectedImg1] = useState("");
  const [selectedImg2, setSelectedImg2] = useState("");
  const [selectedImg3, setSelectedImg3] = useState("");
  const [selectedImg4, setSelectedImg4] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [previewSource2, setPreviewSource2] = useState("");
  const [previewSource3, setPreviewSource3] = useState("");
  const [previewSource4, setPreviewSource4] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [fileInputState2, setFileInputState2] = useState("");
  const [fileInputState3, setFileInputState3] = useState("");
  const [fileInputState4, setFileInputState4] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleFileInputChange1 = (e) => {
    const file = e.target.files[0];
    previewFile1(file);
    setSelectedImg1(file);
    setFileInputState1(e.target.value);
    setCategory("");
    setPlatform("");
    setType("");
    setSummary("");
    setRequiredInfluencers("");
    setCpm("");
  };

  const handleFileInputChange2 = (e) => {
    const file = e.target.files[0];
    previewFile2(file);
    setSelectedImg2(file);
    setFileInputState2(e.target.value);
  };
  const handleFileInputChange3 = (e) => {
    const file = e.target.files[0];
    previewFile3(file);
    setSelectedImg3(file);
    setFileInputState3(e.target.value);
  };
  const handleFileInputChange4 = (e) => {
    const file = e.target.files[0];
    previewFile4(file);
    setSelectedImg4(file);
    setFileInputState4(e.target.value);
  };

  const previewFile1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource1(reader.result);
    };
  };

  const previewFile2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource2(reader.result);
    };
  };

  const previewFile3 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource3(reader.result);
    };
  };
  const previewFile4 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource4(reader.result);
    };
  };

  const handleImage1 = (e) => {
    e.preventDefault();
    if (!selectedImg1) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg1);
    reader.onloadend = () => {
      uploadImage1(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const handleImage2 = (e) => {
    e.preventDefault();
    if (!selectedImg2) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg2);
    reader.onloadend = () => {
      uploadImage2(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };
  const handleImage3 = (e) => {
    e.preventDefault();
    if (!selectedImg3) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg3);
    reader.onloadend = () => {
      uploadImage3(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };
  const handleImage4 = (e) => {
    e.preventDefault();
    if (!selectedImg4) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg4);
    reader.onloadend = () => {
      uploadImage4(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const uploadImage1 = async (base64EncodedImage) => {
    try {
      setLoading1(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign/cover",
        { data: base64EncodedImage },
        config
      );
      setFileInputState1("");
      setPreviewSource1("");
      setCover(data.url);
      setLoading1(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const uploadImage2 = async (base64EncodedImage) => {
    try {
      setLoading2(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign/sample",
        { data: base64EncodedImage },
        config
      );
      setFileInputState2("");
      setPreviewSource2("");
      setSample(data.url);
      setLoading2(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const uploadImage3 = async (base64EncodedImage) => {
    try {
      setLoading3(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign/sample",
        { data: base64EncodedImage },
        config
      );
      setFileInputState3("");
      setPreviewSource3("");
      setSample1(data.url);
      setLoading3(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };
  const uploadImage4 = async (base64EncodedImage) => {
    try {
      setLoading4(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign/sample",
        { data: base64EncodedImage },
        config
      );
      setFileInputState4("");
      setPreviewSource4("");
      setSample2(data.url);
      setLoading4(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const create = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign",
        {
          name: name,
          category: category,
          platform: platform,
          description: desc,
          type: type,
          url: url,
          instruction: summary,
          requiredInfluencers: requiredInfluencers,
          cpm: cpm,
          cover: cover,
          sample: sample,
          // cost: cost,
        },
        config
      );
      console.log(data);
      toast.success("Campaign created successfully");
 
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
      console.log("puyj", Cookie.get("token"));
    }
  };

  return (
    <div className="p-[20px] md:p-2 overflow-y-scroll lg:h-[calc(100vh-102px)] w-full">
      <div className="md:w-full md:p-[15px]">
        <form onSubmit={create}>
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Affiliate Campaign name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="w-full mb-[10px] text-[#b2aeae] flex justify-between space-x-[20px]">
            <div className="w-[50%]">
              <input
                className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
                type="text"
                placeholder="Comission"
                required
              />
            </div>
            <div className="w-[50%]">
              <input
                className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
                placeholder="Percentage 0r Value"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          {/* date 1  */}
          <div className="w-full mb-[10px] text-[#b2aeae] flex justify-between space-x-[20px]">
            <div className="w-[50%]">
            <DatePicker
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="Start Date"
      />
              {/* <input
                className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
                type="date"
                placeholder="Comission"
                required
              /> */}
            </div>
            {/* date component  */}
            <div className="w-[50%]">
            <DatePicker
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="End Date"
      />
              {/* <input
                className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
                placeholder="End Date"
                type={"date"}
                value={name}
                onChange={(e) => setName(e.target.value)}
              /> */}
            </div>
          </div>
          <textarea
            className="w-full mb-[10px] pl-6 focus:outline-none p-3"
            placeholder="Guide Lines"
            rows={6}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Platforms Allows Can Be More Than One"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <h1 className="font-bold my-[10px]">Redirect url</h1>
          <input
            className="pl-6 border border-blue-300 rounded-3xl text-blue-500 p-3 w-full focus:outline-none"
            type="text"
            placeholder="Link in bio or swipe up url(story)"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />

          <h1 className="font-bold my-[15px]">Drop Your Images </h1>

            {/* cover upload  */}
            <div className="bg-white px-5 py-[30px]">
              {!loading1 ? (
                <label className="flex items-center flex-col" htmlFor="pt1">
                  <img
                    className="w-32 h-24 cursor-pointer"
                    src={
                      previewSource1
                        ? previewSource1
                        : cover
                        ? cover
                        : "/images/cover.png"
                    }
                    alt="drop"
                  />
                  {selectedImg1 && (
                    <h1
                      className="mt-[10px] cursor-pointer"
                      onClick={handleImage1}
                    >
                      Ok
                    </h1>
                  )}
                </label>
              ) : (
                <SpinnerCircular
                  size="90"
                  className="w-full flex items-center mx-auto"
                  thickness="100"
                  speed="600"
                  color="white"
                  secondaryColor="black"
                />
              )}
              <input
                accept="image/png, image/jpeg, image/jpg"
                id="pt1"
                type="file"
                hidden
                value={fileInputState1}
                onChange={handleFileInputChange1}
              />
            </div>
            <h6 className="text-center text-blue-400 mt-[0.5vw]">1920*1080</h6>
          <div className="flex justify-between w-full mt-[1vw] mobile:flex-col xsm:flex-col xsm:space-y-[25px]">
            {/* second upload  */}
            <div className="shadow-1xl">

            <div className="bg-white px-5 py-[30px]">
              {!loading2 ? (
                <label className="flex items-center flex-col" htmlFor="pt2">
                  <img
                    className="w-32 h-24 cursor-pointer"
                    src={
                      previewSource2
                        ? previewSource2
                        : sample
                        ? sample
                        : "/images/upl.png"
                    }
                    alt="drop"
                  />
                  {selectedImg2 && (
                    <h1
                      className="mt-[10px] cursor-pointer"
                      onClick={handleImage2}
                    >
                      Ok
                    </h1>
                  )}
                </label>
              ) : (
                <SpinnerCircular
                  size="90"
                  className="w-full flex items-center mx-auto"
                  thickness="100"
                  speed="600"
                  color="white"
                  secondaryColor="black"
                />
              )} 

              <input
                id="pt2"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                hidden
                value={fileInputState2}
                onChange={handleFileInputChange2}
              />
            </div>

            <h6 className="text-center text-blue-400 mt-[1vw]">(Image Or Video)</h6>

            </div>
           
            {/* third  */}
            <div className="shadow-1xl">
            <div className="bg-white px-5 py-[30px]">
              {!loading3 ? (
                <label className="flex items-center flex-col" htmlFor="pt3">
                  <img
                    className="w-32 h-24 cursor-pointer"
                    src={
                      previewSource3
                        ? previewSource3
                        : sample1
                        ? sample1
                        : "/images/upl.png"
                    }
                    alt="drop"
                  />
                  {selectedImg3 && (
                    <h1
                      className="mt-[10px] cursor-pointer"
                      onClick={handleImage3}
                    >
                      Ok
                    </h1>
                  )}
                </label>
              ) : (
                <SpinnerCircular
                  size="90"
                  className="w-full flex items-center mx-auto"
                  thickness="100"
                  speed="600"
                  color="white"
                  secondaryColor="black"
                />
              )}

              <input
                id="pt3"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                hidden
                value={fileInputState3}
                onChange={handleFileInputChange3}
              />
            </div>
            <h6 className="text-center text-blue-400 mt-[1vw] ">(Image Or Video)</h6>

            </div>
            {/* fourth  */}
            <div className="shadow-1yxl">
            <div className="bg-white px-5 py-[30px]">
              {!loading4 ? (
                <label className="flex items-center flex-col" htmlFor="pt4">
                  <img
                    className="w-32 h-24 cursor-pointer"
                    src={
                      previewSource4
                        ? previewSource4
                        : sample2
                        ? sample2
                        : "/images/upl.png"
                    }
                    alt="drop"
                  />
                  {selectedImg4 && (
                    <h1
                      className="mt-[10px] cursor-pointer"
                      onClick={handleImage4}
                    >
                      Ok
                    </h1>
                  )}
                </label>
              ) : (
                <SpinnerCircular
                  size="90"
                  className="w-full flex items-center mx-auto"
                  thickness="100"
                  speed="600"
                  color="white"
                  secondaryColor="black"
                />
              )}

              <input
                id="pt4"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                hidden
                value={fileInputState4}
                onChange={handleFileInputChange4}
              />
            </div>
            <h6 className="text-center text-blue-400 mt-[1vw] ">(Image Or Video)</h6>

            </div>
          </div>

          <input
            className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-blue-400"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
}

export default Formaffiliate;
