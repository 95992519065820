import React, { useState } from "react";
// import toast from "react-hot-toast";
// import Cookie from "js-cookie";
// import axios from "axios";
// import { SpinnerCircular } from "spinners-react";
import { useNavigate } from "react-router-dom";
import AffiliateViewLinkButton from "./AffiliateViewLinkButton";
function Affiliate() {
  const navigate = useNavigate();

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [platform, setPlatform] = useState("");
  // const [desc, setDesc] = useState("");
  // const [type, setType] = useState("post");
  // const [summary, setSummary] = useState("");
  // const [url, setUrl] = useState("");
  // const [requiredInfluencers, setRequiredInfluencers] = useState("");
  // const [cpm, setCpm] = useState("");
  // const [cost, setCost] = useState("");
  // const [visible1, setVisible1] = useState(false);
  // const [visible2, setVisible2] = useState(false);
  // const [cover, setCover] = useState("");
  // const [sample, setSample] = useState("");
  // const [selectedImg1, setSelectedImg1] = useState("");
  // const [previewSource1, setPreviewSource1] = useState("");
  // const [selectedImg2, setSelectedImg2] = useState("");
  // const [previewSource2, setPreviewSource2] = useState("");
  // const [loading1, setLoading1] = useState(false);
  // const [loading2, setLoading2] = useState(false);
  // const [fileInputState1, setFileInputState1] = useState("");
  // const [fileInputState2, setFileInputState2] = useState("");
  const [visible, setVisible] = useState(false);
  // const config = {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //   },
  // };
  // const handleFileInputChange1 = (e) => {
  //   const file = e.target.files[0];
  //   previewFile1(file);
  //   setSelectedImg1(file);
  //   // setFileInputState1(e.target.value);
  // };

  // const handleFileInputChange2 = (e) => {
  //   const file = e.target.files[0];
  //   previewFile2(file);
  //   setSelectedImg2(file);
  //   // setFileInputState2(e.target.value);
  // };

  // const previewFile1 = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     // setPreviewSource1(reader.result);
  //   };
  // };

  // const previewFile2 = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     // setPreviewSource2(reader.result);
  //   };
  // };

  // const handleImage1 = (e) => {
  //   e.preventDefault();
  //   if (!selectedImg1) return;
  //   const reader = new FileReader();
  //   reader.readAsDataURL(selectedImg1);
  //   reader.onloadend = () => {
  //     uploadImage1(reader.result);
  //   };
  //   reader.onerror = () => {
  //     console.error("AHHHHHHHH!!");
  //   };
  // };

  // const handleImage2 = (e) => {
  //   e.preventDefault();
  //   if (!selectedImg2) return;
  //   const reader = new FileReader();
  //   reader.readAsDataURL(selectedImg2);
  //   reader.onloadend = () => {
  //     uploadImage2(reader.result);
  //   };
  //   reader.onerror = () => {
  //     console.error("AHHHHHHHH!!");
  //   };
  // };

  // const uploadImage1 = async (base64EncodedImage) => {
  //   try {
  //     setLoading1(true);
  //     const { data } = await axios.post(
  //       "https://backend-vsal.onrender.com/api/campaign/cover",
  //       { data: base64EncodedImage },
  //       config
  //     );
  //     setFileInputState1("");
  //     setPreviewSource1("");
  //     setCover(data.url);
  //     setLoading1(false);
  //     toast.success("Image uploaded");
  //   } catch (err) {
  //     console.error(err);
  //     toast.error(err?.response?.data?.message);
  //   }
  // };

  // const uploadImage2 = async (base64EncodedImage) => {
  //   try {
  //     setLoading2(true);
  //     const { data } = await axios.post(
  //       "https://backend-vsal.onrender.com/api/campaign/sample",
  //       { data: base64EncodedImage },
  //       config
  //     );
  //     setFileInputState2("");
  //     setPreviewSource2("");
  //     setSample(data.url);
  //     setLoading2(false);
  //     toast.success("Image uploaded");
  //   } catch (err) {
  //     console.error(err);
  //     toast.error(err?.response?.data?.message);
  //   }
  // };
  // copied data please change api and states

  return (
    <div className="p-[20px] md:p-2 overflow-y-scroll lg:h-[calc(100vh-102px)] w-full">
      {!visible ? (
        <div className="flex flex-col space-y-[10px]">
          {/* first row  */}
          <div className="flex flex-col justify-center items-center sm:flex-row px-[20px] py-[10px] bg-white mobile:flex-col xsm:flex-col">
            {/* images  */}
            <img className="sm:w-auto sm:h-auto w-full h-full" src="../Images/laptop.png" alt="" />
            {/* right text  */}
            <div className="flex flex-col px-[10px] w-full">
              {/* texts  */}
              <div className="w-full flex justify-between ">
                <h1 className="font-bold text-lg">Affiliate Campaign Name</h1>
                <h1 className="font-bold text-lg">Platforms</h1>
              </div>
              {/* text  */}
              <div className=" flex justify-between">
                <h1 className="text-[#707070] text-md font-semibold">
                  23-04-2023 &#9472; 26-05-2023
                </h1>
              </div>

              {/* visit row  */}
              <div
                className="mt-5 flex items-center justify-between 2xl:mt-5"
                onClick={() => {}}
              >
                <h1 className="text-[16px] font-bold mobile:text-[14px]">Commisions</h1>

           <AffiliateViewLinkButton link="1245"/>
              </div>
            </div>
          </div>
          {/* dublicate row  */}
          <div className="flex flex-col justify-center items-center sm:flex-row px-[20px] py-[10px] bg-white mobile:flex-col xsm:flex-col">
            {/* images  */}
            <img className="sm:w-auto sm:h-auto w-full h-full" src="../Images/laptop.png" alt="" />
            {/* right text  */}
            <div className="flex flex-col px-[10px] w-full">
              {/* texts  */}
              <div className="w-full flex justify-between ">
                <h1 className="font-bold text-lg">Affiliate Campaign Name</h1>
                <h1 className="font-bold text-lg">Platforms</h1>
              </div>
              {/* text  */}
              <div className=" flex justify-between ">
                <h1 className="text-[#707070] text-md font-semibold">
                  23-04-2023 &#9472; 26-05-2023
                </h1>
              </div>

              {/* visit row  */}
              <div
                className="mt-5 flex items-center justify-between 2xl:mt-5"
                onClick={() => {}}
              >
                <h1 className="text-[16px] font-bold mobile:text-[14px]">Commisions</h1>

                <AffiliateViewLinkButton link="1245"/>
              </div>
            </div>
          </div>
          {/* dublicate2 row  */}
          <div className="flex flex-col justify-center items-center sm:flex-row px-[20px] py-[10px] bg-white mobile:flex-col xsm:flex-col">
            {/* images  */}
            <img className="sm:w-auto sm:h-auto w-full h-full" src="../Images/laptop.png" alt="" />
            {/* right text  */}
            <div className="flex flex-col px-[10px] w-full">
              {/* texts  */}
              <div className="w-full flex justify-between ">
                <h1 className="font-bold text-lg">Affiliate Campaign Name</h1>
                <h1 className="font-bold text-lg">Platforms</h1>
              </div>
              {/* text  */}
              <div className=" flex justify-between ">
                <h1 className="text-[#707070] text-md font-semibold">
                  23-04-2023 &#9472; 26-05-2023
                </h1>
              </div>

              {/* visit row  */}
              <div
                className="mt-5 flex items-center justify-between 2xl:mt-5"
                onClick={() => {}}
              >
                <h1 className="text-[16px] font-bold mobile:text-[14px]">Commisions</h1>

            <AffiliateViewLinkButton link="1245"/>
              </div>
            </div>
          </div>
          
          {/* create btn  */}

          <div
                className="flex 8k:text-[1vw] 3xl:text-[1vw] 4k:text-[1.3vw] cursor-pointer items-center rounded-full p-1 sm:p-2 sm:text-lg mx-4 bg-[#00A3FF] w-[38%] sm:w-[20%] absolute bottom-10 right-10 lgk:w-[20%] justify-evenly lgk:justify-evenly xsm:justify-around xsm:w-[65%] xsm:bottom-0 xsm:relative xsm:items-center xsm:left-[30%]  mobile:justify-around mobile:w-[85%] mobile:bottom-0 mobile:relative mobile:items-center mobile:left-[10%] transition duration-300 ease-in-out hover:bg-blue-900" 
                onClick={() => {
                  setVisible(true);
                  navigate("/affiliateform/:1245");
                }}
              >
                <h1 className="2xl:px-[10px] text-xs md:text-base xl:ml-[20px] lg:ml-[10px] lg:text-[15px] my-[10px] xl:text-[14px]  xl:w-[80%] text-white 8k:text-3xl 3xl:text-[1vw]">
                  Create Campaign
                 </h1>
                <img
                  className="w-4 h-4 mr-7 8k:w-[1vw] 8k:h-[1vw] 4k:w-[1vw] 4k:h-[1vw] 3xl:w-[1vw] 3xl:h-[1vw]"
                  src="/Images/plus.png"
                  alt="logo"
                />
              </div>
        </div>
      ) : (
        <div className="my-[50px] flex items-center justify-center h-[calc(100vh-60px)] xl:w-[70%] w-full lg:h-[calc(100vh-105px)] sm:my-[50px] md:my-[50px] mobile:my-[30px] mobile:flex mobile:items-center mobile:justify-center">
          <div className="space-y-[20px] h-[60%] md:basis-[50%] px-2 mr-[20px] ">
            <form className="bg-white px-[20px] space-y-[20px] justify-between flex-col flex py-[20px] rounded-lg ">
              <h1 className=" text-[#707070] xl:ml-[20px] lg:ml-[10px] lg:text-[10px] my-[10px] xl:text-[14px]  xl:w-[80%]  8k:text-3xl 3xl:text-[1vw]">
                No Affiliate Campaigns Found
                {/* <span className="xl:hidden flex">Create</span> */}
              </h1>
              <div
                className="flex justify-center cursor-pointer items-center rounded-full p-2 mx-4 bg-[#00A3FF]"
                onClick={() => {
                  setVisible(true);
                  navigate("/affiliateform/:1245");
                }}
              >
                <h1 className="xl:ml-[20px] lg:ml-[10px] lg:text-[10px] my-[10px] xl:text-[14px]  xl:w-[80%] text-white 8k:text-3xl 3xl:text-[1vw]">
                  Create Campaign
                  {/* <span className="xl:hidden flex">Create</span> */}
                </h1>
                <img
                  className="w-4 h-4 8k:w-9 8k:h-9 3xl:h-[1vw] 3xl:w-[1vw]"
                  src="/Images/plus.png"
                  alt="logo"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Affiliate;
