import React, { useState } from "react";
import toast from "react-hot-toast";
// import Cookie from "js-cookie";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";

function CreateCommutiny() {
  const [micro, setMicro] = useState(false);
  const [nano, setNano] = useState(true);
  const [mega, setMega] = useState(false);
  const [any, setAny] = useState(false);
  const [name, setName] = useState("");
  const [mid, setMid] = useState(false);
  const [macro, setMacro] = useState(false);
  const [post, setPost] = useState(true);
  const [story, setStory] = useState(false);
  const [reels, setReels] = useState(false);
  const [category, setCategory] = useState("");
  const [platform, setPlatform] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("post");
  const [summary, setSummary] = useState("");
  const [url, setUrl] = useState("");
  const [requiredInfluencers, setRequiredInfluencers] = useState("");
  const [cpm, setCpm] = useState("");
  // const [cost, setCost] = useState("");
  // const [visible1, setVisible1] = useState(false);
  // const [visible2, setVisible2] = useState(false);
  const [cover, setCover] = useState("");
  const [sample, setSample] = useState("");
  const [selectedImg1, setSelectedImg1] = useState("");
  const [previewSource1, setPreviewSource1] = useState("");
  const [selectedImg2, setSelectedImg2] = useState("");
  const [previewSource2, setPreviewSource2] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [fileInputState1, setFileInputState1] = useState("");
  const [fileInputState2, setFileInputState2] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const handleFileInputChange1 = (e) => {
    const file = e.target.files[0];
    previewFile1(file);
    setSelectedImg1(file);
    setFileInputState1(e.target.value);
    setDesc("");
  };

  const handleFileInputChange2 = (e) => {
    const file = e.target.files[0];
    previewFile2(file);
    setSelectedImg2(file);
    setFileInputState2(e.target.value);
  };

  const previewFile1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource1(reader.result);
    };
  };

  const previewFile2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource2(reader.result);
    };
  };

  const handleImage1 = (e) => {
    e.preventDefault();
    if (!selectedImg1) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg1);
    reader.onloadend = () => {
      uploadImage1(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const handleImage2 = (e) => {
    e.preventDefault();
    if (!selectedImg2) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg2);
    reader.onloadend = () => {
      uploadImage2(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const uploadImage1 = async (base64EncodedImage) => {
    try {
      setLoading1(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign/cover",
        { data: base64EncodedImage },
        config
      );
      setFileInputState1("");
      setPreviewSource1("");
      setCover(data.url);
      setLoading1(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const uploadImage2 = async (base64EncodedImage) => {
    try {
      setLoading2(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign/sample",
        { data: base64EncodedImage },
        config
      );
      setFileInputState2("");
      setPreviewSource2("");
      setSample(data.url);
      setLoading2(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const create = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/campaign",
        {
          name: name,
          category: category,
          platform: platform,
          description: desc,
          type: type,
          url: url,
          instruction: summary,
          requiredInfluencers: requiredInfluencers,
          cpm: cpm,
          cover: cover,
          sample: sample,
          // cost: cost,
        },
        config
      );
      console.log(data);
      toast.success("Campaign created successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="p-[20px] md:p-2 overflow-y-scroll lg:h-[calc(100vh-102px)] w-full">
      <div className="md:w-full md:p-[15px]">
        <h1 className="font-extrabold ">Create Your First Campaign</h1>
        <form onSubmit={create}>
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Campaign name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="w-full mb-[10px] text-[#b2aeae] flex justify-between space-x-[20px]">
            <div className="w-[50%]">
              <select
                className="w-full py-2 focus:outline-none pl-6"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option>Select Category</option>
                <option value="Sports">Sports</option>
                <option value="Entertainment">Entertainment</option>
              </select>
            </div>
            <div className="w-[50%]">
              <select
                className="w-full py-2 pl-6 focus:outline-none"
                onChange={(e) => setPlatform(e.target.value)}
              >
                <option>Select Platform</option>
                <option value="Instagram">Instagram</option>
                <option value="Facebook">Facebook</option>
                <option value="Youtube">Youtube</option>
              </select>
            </div>
          </div>
            
          <h1 className="font-bold">Enter Campaign Type</h1>
          <div className="w-full my-[10px] flex justify-between">
            <h1
              className={
                !post
                  ? "md:px-12 px-8 cursor-pointer py-2 border border-blue-300 bg-blue-200 rounded-3xl text-[#b2aeae]"
                  : "md:px-12 px-8 cursor-pointer py-2 bg-blue-500 rounded-3xl text-white"
              }
              onClick={() => {
                setPost(true);
                setReels(false);
                setStory(false);
                setType("Post");
              }}
            >
              Post
            </h1>
            <h1
              className={
                !story
                  ? "md:px-12 px-8 cursor-pointer py-2 border border-blue-300 bg-blue-200 rounded-3xl text-[#b2aeae]"
                  : "md:px-12 px-8 cursor-pointer py-2 bg-blue-500 rounded-3xl text-white"
              }
              onClick={() => {
                setStory(true);
                setPost(false);
                setReels(false);
                setType("Story");
              }}
            >
              Story
            </h1>
            <h1
              className={
                !reels
                  ? "md:px-12 px-8 cursor-pointer py-2 border border-blue-300 bg-blue-200 rounded-3xl text-[#b2aeae]"
                  : "md:px-12 px-8 cursor-pointer py-2 bg-blue-500 rounded-3xl text-white"
              }
              onClick={() => {
                setReels(true);
                setPost(false);
                setStory(false);
                setType("Reels");
              }}
            >
              Reels
            </h1>
          </div>
          <p className="text-[#b2aeae] pl-2 text-[11px] w-full">
            Post Description: (may contain product description,hashtags,link to
            insta pages)
          </p>
          <h1 className="font-bold my-[10px]">Redirect url</h1>
          <input
            className="pl-6 border border-blue-300 rounded-3xl text-blue-500 p-3 w-full focus:outline-none"
            type="text"
            placeholder="Link in bio or swipe up url(story)"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <h1 className="font-bold my-[10px]">Instructions for influencers</h1>
          <textarea
            className="w-full pl-6 focus:outline-none p-3"
            placeholder="Instructions"
            rows={6}
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
          <h1 className="font-bold my-[10px]">Select Followers Limits</h1>
          <div className="flex flex-wrap gap-[10px]">
            <h1
              className={
                !nano
                  ? "w-fit cursor-pointer px-[9px] text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setNano(!nano);
                setMacro(false);
                setAny(false);
                setMega(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Nano Influencers: 5K - 10K
            </h1>
            <h1
              className={
                !micro
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMicro(!micro);
                setMacro(false);
                setAny(false);
                setMega(false);
                setNano(false);
                setMid(false);
              }}
            >
              Micro: 10K - 50K
            </h1>
            <h1
              className={
                !mega
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMega(!mega);
                setMacro(false);
                setAny(false);
                setNano(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Mega: 1M - 20M
            </h1>
            <h1
              className={
                !mid
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMid(!mid);
                setMacro(false);
                setAny(false);
                setMega(false);
                setMicro(false);
                setNano(false);
              }}
            >
              Mid Tier: 50K - 500K
            </h1>
            <h1
              className={
                !macro
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setMacro(!macro);
                setNano(false);
                setAny(false);
                setMega(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Macro: 500K - 1M
            </h1>
            <h1
              className={
                !any
                  ? "px-[9px] w-fit cursor-pointer text-[#b2aeae] py-2 border border-blue-300 rounded-3xl"
                  : "text-white cursor-pointer px-[9px] w-fit py-2 bg-blue-500 rounded-3xl"
              }
              onClick={() => {
                setAny(!any);
                setMacro(false);
                setNano(false);
                setMega(false);
                setMicro(false);
                setMid(false);
              }}
            >
              Get any followers
            </h1>
          </div>
          <h1 className="text-[#b2aeae] my-[10px] py-2 bg-white text-center">
            Influencers Found:
            <span className="font-bold text-blue-500"> 5000</span>
          </h1>
          <h1 className="font-bold">Influencers Required</h1>
          <input
            className="text-[#b2aeae] focus:outline-none w-full my-[10px] py-2 bg-white pl-[10px]"
            type="number"
            placeholder="Drop the value in Numeric"
            value={requiredInfluencers}
            onChange={(e) => setRequiredInfluencers(e.target.value)}
          />
          <div className="">
            <h1 className="font-bold">Summary</h1>
          </div>
          <div className="flex justify-between items-center w-full mt-[10px] mb-[10px]">
            <h1 className="text-[#b2aeae]">Approx. Followers Reach</h1>
            <h1 className="font-bold  text-end">500k</h1>
          </div>
          <h1 className="font-bold mb-[10px]">Drop Your Images </h1>
          <div className="flex justify-between w-full">
            <div className="bg-white px-5 py-[10px]">
              {!loading1 ? (
                <label className="flex items-center flex-col" htmlFor="pt1">
                  <img
                    className="w-32 h-24 cursor-pointer"
                    src={
                      previewSource1
                        ? previewSource1
                        : cover
                        ? cover
                        : "/images/cover.png"
                    }
                    alt="drop"
                  />
                  {selectedImg1 && (
                    <h1
                      className="mt-[10px] cursor-pointer"
                      onClick={handleImage1}
                    >
                      Ok
                    </h1>
                  )}
                </label>
              ) : (
                <SpinnerCircular
                  size="90"
                  className="w-full flex items-center mx-auto"
                  thickness="100"
                  speed="600"
                  color="white"
                  secondaryColor="black"
                />
              )}
              <input
                accept="image/png, image/jpeg, image/jpg"
                id="pt1"
                type="file"
                hidden
                value={fileInputState1}
                onChange={handleFileInputChange1}
              />
            </div>
            <div className="bg-white px-5 py-[10px]">
              {!loading2 ? (
                <label className="flex items-center flex-col" htmlFor="pt2">
                  <img
                    className="w-32 h-24 cursor-pointer"
                    src={
                      previewSource2
                        ? previewSource2
                        : sample
                        ? sample
                        : "/images/upl.png"
                    }
                    alt="drop"
                  />
                  {selectedImg2 && (
                    <h1
                      className="mt-[10px] cursor-pointer"
                      onClick={handleImage2}
                    >
                      Ok
                    </h1>
                  )}
                </label>
              ) : (
                <SpinnerCircular
                  size="90"
                  className="w-full flex items-center mx-auto"
                  thickness="100"
                  speed="600"
                  color="white"
                  secondaryColor="black"
                />
              )}

              <input
                id="pt2"
                accept="image/png, image/jpeg, image/jpg"
                type="file"
                hidden
                value={fileInputState2}
                onChange={handleFileInputChange2}
              />
            </div>
          </div>
          <h1 className="font-bold mt-[10px]">CPM</h1>
          <input
            type="text"
            className="text-[#b2aeae] focus:outline-none w-full mt-[10px] py-2 bg-white pl-[10px]"
            placeholder="CPM (Cost per 1000 followers)"
            value={cpm}
            onChange={(e) => setCpm(e.target.value)}
          />

          <input
            className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-blue-400"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
}

export default CreateCommutiny;
