// import React, { useEffect,useState } from "react";
// import toast from "react-hot-toast";
// import axios from "axios";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { useNavigate } from "react-router-dom";
import ButtonCampaign from "./ButtonCampaign";

const datach = [
  { name: "Page A", uv: 120, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 280, pv: 1000, amt: 1200 },
  { name: "Page C", uv: 380, pv: 1800, amt: 1200 },
  { name: "Page D", uv: 150, pv: 2000, amt: 1200 },
];
function Campaign() {
  const navigate = useNavigate();
  // const [active, setActive] = useState([]);
  // const [data, setData] = useState([]);
    const data=[
      {
        _id: "60f9b0b3e6b3a40015f1a3a1",
        cover:"/Images/cocacola.png",
        name: "CocaCola",
        status: "Active",
      },
      {
        _id: "60f9b0b3e6b3a40015f1a3a2",
        cover:"/Images/cocacola.png",
        name: "CocaCola",
        status: "Active",
      },
      {
        _id: "60f9b0b3e6b3a40015f1a3a3",
        cover:"/Images/cocacola.png",
        name: "CocaCola",
        status: "Active",
      },
      {
        _id: "60f9b0b3e6b3a40015f1a3a4",
        cover:"/Images/cocacola.png",
        name: "CocaCola",
        status: "Active",
      },
      {
        _id: "60f9b0b3e6b3a40015f1a3a5",
        cover:"/Images/cocacola.png",
        name: "CocaCola",
        status: "Active",
      },
      {
        _id: "60f9b0b3e6b3a40015f1a3a6",
        cover:"/Images/cocacola.png",
        name: "CocaCola",
        status: "Active",
      },
    ];
  // console.log(active);
//   useEffect(() => {
//   const accept = async (id, money) => {
//     try {
//       const { data } = await axios.post(
//         "https://backend-vsal.onrender.com/api/order/" + id,
//         { money: money },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       setData(data);
//       console.log("campaigns list",data);
//     } catch (error) {
//       console.log(error);
//       if (error?.status === 404) {
//         toast.error("Please recharge your wallet");
//       }
//       toast.error("Something went wrong");
//     }
//   };
//   accept();
// }, []);

  return (
    <div className="p-[20px] mr-[10px] md:p-2 space-y-[20px] ">
      <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-[10px] 8k:w-[115vh] 4k:w-[77vw]  8k:w-[280vh] 3xl:mt-[1.5vw] lgk:pr-[2vw]">
        {/* graph  */}
        <div className="xl:w-[554px] lg:w-[300px] lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white 8k:w-[60vh] 8k:p-3 8k:mt-9 4k:w-[55vw] 4k:mt-9 3xl:w-[60%] rk:py-[1vw]
        1440k:h-[29vw]  lgk:w-[32vw] 2xl:w-[100%]">
          <div className="flex justify-between items-center">
            <h1 className="text-black font-extrabold text-md md:text-lg mr-[10px] 8k:text-3xl 8k:font-bold 4k:text-xl 4k:font-bold 3xl:text-[1.3vw] 3xl:font-bold rk:text-[1vw] rk:font-bold 1440k:font-bold lgk:font-bold lgk:text-[1.2vw] ">
              Sales Generated
            </h1>
            <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] 8k:text-xl 4k:text-lg mobile:w-[15vh] mobile:h-[4vh] mobile:text-xs lgk:text-[1vw] lgk:w-[10vw] lgk:px-0 ">
              <select className="w-full rounded-full py-2 pl-6 focus:outline-none mobile:p-1 lgk:px-[0.5vw] ">
                <option value="Year">Year</option>
                <option value="Month">Month</option>
              </select>
            </div>
          </div>

          {/* <div className="graph  md:w-full w-[99%] 3xl:h-[100%]  4k:h-[100%] 1440k:h-[100%] lgk:h-[100%] lgk:w-[100%] "> */}
          <ResponsiveContainer  >
            <LineChart data={datach}>
              <Line type="monotone" dataKey="uv" stroke="#8884d8" strokeWidth={8} />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
            {/* <Line options={options} data={data} /> */}
           {/* </div> */}
        </div>
{/* section  */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
{/* 1card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh] "
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw]  lgk:mb-[0]">8.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw] lgk:text-[1.2vw] ">
                Approved Influencers
              </div>
            </div>
{/* 2 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/click.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="text-lg font-extrabold text-black mt-[10px] mb-[10px] 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">2.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                clicks
              </div>
            </div>
          </div>

          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0  4k:w-[26vw] lg:flex-row  8k:ml-[2vh] 8k:my-[5vh]  8k:h-[12vh] 3xl:text-[1.3vw] 3xl:h-[8vw] lgk:w-[40vw]">
            {/* 3rd card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/sales.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">8.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total spent
              </div>
            </div>
{/* 4 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">2.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Pending Influencers
              </div>
            </div>
          </div>

          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0 4k:w-[26vw] lg:flex-row 8k:ml-[2vh] 8k:mt-[2vh]  8k:h-[12vh]  lgk:w-[40vw] 3xl:text-[1.3vw] 3xl:h-[8vw]">
            {/* 5 card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/reach.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">8.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Potential reach
              </div>
            </div>
{/* 6 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/social_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">2.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total Posts
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className="text-lg text-black font-extrabold 8k:text-3xl 8k:font-bold 8k:py-9">Conversion Campaign List</div>
 

<div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
          <table className="table-auto md:gap-2 text-center lg:w-[95%]">
            <thead>
              <tr>
                <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8">Campaign Name</th>
                <th className="text-xs pr-[20px] md:text-[14px] pl-2">status</th>
                <th className="text-xs pr-[20px] md:text-[14px]">No. of Influencers</th>
                <th className="text-xs pr-[20px] md:text-[14px]">Approved Influencers</th>
                <th className="text-xs pr-[20px] md:text-[14px]">Rejected Influencers</th>
                <th className="text-xs pr-[20px] md:text-[14px] pl-1">Spent</th>
                <th className="text-xs pr-[20px] md:text-[14px]">Clicks</th>
                <th className="text-xs pr-[20px] md:text-[14px] pl-4">Conversions</th>
                <th className="text-xs pr-[60px] md:text-[14px]">Action</th> 
              </tr>
            </thead>
            <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
              {
              data.map((i) => (
              <tr key={i._id} className="">
                <td className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer "
                onClick={()=>{
                  navigate(`/campaign/123`);
                }}
                >
                  <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                    <img
                      className="w-6 mr-[5px] h-6 rounded-full"
                      src={i.cover}
                      alt="profile"
                    />
                    <h1>{i.name}</h1>
                    {/* <h1 className="mx-[5px]">Campaign</h1> */}
                  </div>
                </td>
                <td className="text-md pt-[20px] text-[#00B528]  md:text-[14px] font-bold">
                {i.status}
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                  5
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                  6
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-8">
                  8
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                  7000
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                  49
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-2">
                  300
                </td>
                <td className="flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <ButtonCampaign label="Pause" color="#D6F0FF" tcolor="black"/>
                </td>
              </tr>
  
               ))} 
            </tbody>
          </table>
        </div>

        <div className="text-lg text-black font-extrabold 8k:text-3xl 8k:font-bold 8k:py-9">Brand Awareness Campaigns List</div>

<div className="bg-white py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full">
          <table className="table-auto md:gap-2 text-center lg:w-[95%]">
            <thead>
              <tr>
                <th className="text-xs pr-[20px] md:text-[14px] font-semibold pl-8">Campaign Name</th>
                <th className="text-xs pr-[20px] md:text-[14px] pl-2">status</th>
                <th className="text-xs pr-[20px] md:text-[14px]">No. of Influencers</th>
                <th className="text-xs pr-[20px] md:text-[14px]">Approved Influencers</th>
                <th className="text-xs pr-[20px] md:text-[14px]">Rejected Influencers</th>
                <th className="text-xs pr-[20px] md:text-[14px] pl-1">Spent</th>
                <th className="text-xs pr-[20px] md:text-[14px]">Clicks</th>
                <th className="text-xs pr-[60px] md:text-[14px]">Action</th> 
              </tr>
            </thead>
            <tbody className="my-[20px] mx-[20px] space-y-[20px] font-medium">
              {
              data.map((i) => (
              <tr key={i._id} className="">
                <td className="text-xs pl-[20px] pt-[20px] md:text-lg cursor-pointer "
                onClick={()=>{
                  navigate(`/campaign/123`);
                }}
                >
                  <div className="flex font-semibold text-[14px] justify-center items-center text-xs text-[#707070]">
                    <img
                      className="w-6 mr-[5px] h-6 rounded-full"
                      src={i.cover}
                      alt="profile"
                    />
                    <h1>{i.name}</h1>
                    {/* <h1 className="mx-[5px]">Campaign</h1> */}
                  </div>
                </td>
                <td className="text-md pt-[20px] text-[#00B528]  md:text-[14px] font-bold">
                {i.status}
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                  5
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] pr-8 font-semibold text-[#707070]">
                  6
                </td>
                <td className="text-xs pt-[20px] md:text-[14px] font-semibold text-[#707070] pr-8">
                  8
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                  7000
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] font-semibold text-[#707070] pr-4">
                  49
                </td>
                <td className="flex pt-[20px] space-x-[5px]  md:text-[14px]">
                <ButtonCampaign label="Pause" color="#D6F0FF" tcolor="black"/>
                </td>
              </tr>
  
               ))} 
            </tbody>
          </table>
        </div>

    </div>
  );
}

export default Campaign;
