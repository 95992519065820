import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { SpinnerCircular } from "spinners-react";
import { loginError, loginStart, loginSuccess } from "../redux/Slice/userSlice";

function Setting() {
  // const { currentUser } = useSelector((state) => state.user);
  const [selectedImg, setSelectedImg] = useState(""); // Image
  // const [previewSource, setPreviewSource] = useState(""); // Image
  const [profile, setProfile] = useState();
  // const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [campany, setCampany] = useState("");
  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [inviteName, setInviteName] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileInputState, setFileInputState] = useState(""); //Image
  // const current = currentUser?.others ? currentUser?.others : currentUser;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedImg(file);
    setFileInputState(e.target.value);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // setPreviewSource(reader.result);
    };
  };

  const handleImage = (e) => {
    e.preventDefault();
    if (!selectedImg) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedImg);
    reader.onloadend = () => {
      uploadImage(reader.result);
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
    };
  };

  const uploadImage = async (base64EncodedImage) => {
    try {
      // setLoading(true);
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/user/upload",
        { data: base64EncodedImage },
        config
      );

      setFileInputState("");
      // setPreviewSource("");
      setProfile(data.url);
      // setLoading(false);
      toast.success("Image uploaded");
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const { data } = await axios.put(
        `https://backend-vsal.onrender.com/api/user/update`,
        {
          name: name,
          email: email,
          password: password,
          username: username,
          phone: phone,
          url: url,
          campany: campany,
          profile: profile,
        },
        config
      );
      dispatch(loginSuccess(data));
      toast.success("Updated successfully");
      setName("");
      setEmail("");
      setPassword("");
      setUrl("");
      setCampany("");
      setPhone("");
      setUsername("");
    } catch (error) {
      dispatch(loginError());
      toast.error(error?.response?.data?.message);
      console.log(error?.response?.data.message);
    }
  };

  const invite = async (e) => {
    e.preventDefault();
    setInviteName("");
    setInviteEmail("");
  }

  const invites=[
    {
    "_id":1,
      "name":"John",
      "email":"john@gmail.com",
      "status":"Active"
    },
    {
      "_id":2,
      "name":"Jake",
      "email":"jake@gmail.com",
      "status":"Paused"
    },
    {
      "_id":3,
      "name":"James",
      "email":"james@gmail.com",
      "status":"In Review"
    },
    {
      "_id":4,
      "name":"Jaden",
      "email":"jaden@gmail.com",
      "status":"Disapproved"
    },
  ]

  const statusColors = {
    'In Review': 'text-blue-500',
    'Active': 'text-green-500',
    'Disapproved': 'text-red-500',
    'Paused': 'text-blue-900',
  };

  return (
    <div className="p-[20px] md:p-2  lg:h-[calc(100vh-102px)] flex flex-col items-center">
    <form onSubmit={invite} className="flex flex-col items-center">
        <div className="flex flex-row space-x-2 items-center justify-center">
          <input
            className="bg-white text-[#b2aeae] my-[10px] p-3 pl-7 w-[33%] focus:outline-none"
            type="text"
            placeholder="Name"
            value={inviteName}
            onChange={(e) => setInviteName(e.target.value)}
          />
          <input
            className="bg-white  text-[#b2aeae] my-[10px] p-3 w-[33%] focus:outline-none"
            type="text"
            placeholder="email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          </div>
          <input
            className="text-center cursor-pointer py-2 text-white w-[66%] my-[10px] rounded-lg bg-blue-500"
            type="submit"
            value={'Invite'}
          />
        </form>
        <div className="flex mt-[10px] mb-[5px] justify-between">
        <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black">
          Invitation Status
        </h1>
        </div>
        {/* table here  */}
        <div className="rounded-lg bg-white relative group py-[10px] w-[55%] p-3 md:w-[55%] ">
          <table className="table-fixed  md:gap-2 text-center lg:w-[100%]">
            <thead>
              <tr className="">
                <th className="text-base pr-[10px] font-semibold md:text-[14px] 8k:text-2xl mobile:px-[20px] 3xl:text-[1vw]">
                  Name
                </th>
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Email
                </th>
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="my-[20px] mx-[20px] space-y-[20px]">
              {
            invites.map((i) => (
              <tr key={i._id} className="">
                <td className="text-xs pt-[20px] md:text-lg font-semibold">
                  {i.name}
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                  {i.email}
                </td>
                <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] ">
                <span className={`text-sm ${statusColors[i.status]} font-semibold`}>
                    {i.status}
                </span>
                </td>
              </tr> 
  
               ))
               
               }
            </tbody>
          </table>
        </div>
      <div className="md:w-[500px] md:p-[28px]">
        <div className="relative flex justify-center group">
          {/* {loading ? (
            <label
              htmlFor="edit"
              // className="absolute -bottom-10 md:left-[204px] left-[174px]"
            >
              
 
              <img
                className="w-[105px] cursor-pointer h-[105px] rounded-full"
                src={
                  previewSource
                    ? previewSource
                    : profile
                    ? profile
                    : current.profile
                }
                alt="profile"
              />
            </label>
          ) : (
            <SpinnerCircular
              size="90"
              className="w-full flex items-center mx-auto"
              thickness="100"
              speed="600"
              color="white"
              secondaryColor="black"
            />
          )} */}

<img
                className="w-[105px] cursor-pointer h-[105px] rounded-full"
                src={'../Images/profile.png'}
                alt="profile"
              />

          <img
            className="w-24 h-24 absolute -bottom-12 md:left-[214px] left-[174px] cursor-pointer sm:left-[50%]"
            src="/Images/galleryedit.png"
            alt="tag"
            onClick={handleImage}
          />

          <input
            id="edit"
            value={fileInputState}
            onChange={handleFileInputChange}
            hidden
            type="file"
          />
        </div>
        <div className="flex my-[20px] items-center justify-center">
          <h1 className="text-black font-bold ">Edit Profile</h1>
          <img className="w-6 h-6 ml-3" src="/Images/useredit.png" alt="edit" />
        </div>

        <form onSubmit={submit}>
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Campany Name"
            value={campany}
            onChange={(e) => setCampany(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Updated Campany website URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="number"
            placeholder="+91"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="email"
            placeholder="Communication Mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <input
            className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-blue-500"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
}

export default Setting;
