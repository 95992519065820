import React,{useState} from 'react';

const ButtonCampaign = ({ label, color, tcolor }) => {
  const [isEnabled, setIsEnabled] = useState(label === 'enabled');
  const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };
  return (
    <button
      className={`cursor-pointer rounded-xl py-2 px-2 font-bold text-xs transition duration-300 ease-in-out ${
        isEnabled
          ? 'bg-blue-500 text-white'
          : 'bg-gray-300 text-black'
      }`}
      onClick={handleToggle}
    >
      {isEnabled ? 'Enabled' : 'Disabled'}
    </button>
  );
};

export default ButtonCampaign;