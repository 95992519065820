import React, { useState,useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
// import Cookies from "js-cookie";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const datach = [
  { name: "Page A", uv: 120, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 280, pv: 1000, amt: 1200 },
  { name: "Page C", uv: 380, pv: 1800, amt: 1200 },
  { name: "Page D", uv: 150, pv: 2000, amt: 1200 },
];


function Main() {
  const [opt1, setOpt1] = useState(true);
  const [opt2, setOpt2] = useState(true);
  const [opt3, setOpt3] = useState(true);
  const [opt4, setOpt4] = useState(false);
  const [opt5, setOpt5] = useState(false);
  const [option, setOption] = useState(false);
  const [opts1, setOpts1] = useState(true);
  const [opts2, setOpts2] = useState(true);
  const [opts3, setOpts3] = useState(true);
  const [opts4, setOpts4] = useState(false);
  const [opts5, setOpts5] = useState(false);
  // const [optst1, setOptst1] = useState(true);
  // const [optst2, setOptst2] = useState(true);
  // const [optst3, setOptst3] = useState(true);
  // const [optst4, setOptst4] = useState(false);
  // const [optst5, setOptst5] = useState(false);
  const [option1, setOption1] = useState(false);
  // const [option2, setOption2] = useState(false);
  // const [data, setData] = useState([]);
  const [active, setActive] = useState([]);
  const [influencer, setInfluencer] = useState([]);

  useEffect(() => {
    const get = async () => {
      try {
        console.log( `Bearer ${localStorage.getItem("token")}`);
        // const { data } = await axios.get("https://backend-vsal.onrender.com/api/campaign");
        const data2 = await axios.get("https://backend-vsal.onrender.com/api/influencer/getInfluencer",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        // const data1 = await axios.get(
        //   "https://backend-vsal.onrender.com/api/campaign/active",
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   }
        // );
        const data1=[
          {
          "_id":1,
            "name":"John",
            "status":"Active"
          },
          {
            "_id":2,
            "name":"Jake",
            "status":"Paused"
          },
          {
            "_id":3,
            "name":"James",
            "status":"In Review"
          },
          {
            "_id":4,
            "name":"Jaden",
            "status":"Disapproved"
          },
        ]
        console.log(data1);
        setActive(data1);
        setInfluencer(data2.data.data);
        // setData(data);
        console.log("data2",data2.data.data);
        console.log("active",data1.data)
      } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
      }
    };
    get();
  }, []);
  const statusColors = {
    'In Review': 'text-blue-500',
    'Active': 'text-green-500',
    'Disapproved': 'text-red-500',
    'Paused': 'text-blue-900',
  };
  return (
     <div className="overflow-y-scroll p-[20px] lg:h-[calc(100vh-102px)] 3xl:text-2xl   ">
      {/* all cards  */}
      <div className="flex lg:flex-row flex-col items-center xl:justify-between lg:justify-center md:justify-self-auto 3xl:justify-center 1440k:justify-center ">
        {/* 4 cards  */}
        <div className="xl:w-[484px] space-y-[10px] md:w-[50%] w-full h-fit lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] py-[0px] 8k:justify-center 1440k:justify-center 4k:justify-center rk:justify-center 3xl:w-[100%] 3xl:h-[100%] 1440k:w-[100%] lgk:w-[100%]">
          {/* section 1 */}
          <div className="flex flex-col lg:flex-row space-y-[10px] lg:space-y-0 8k:w-[53vh] 4k:w-[37vw] 4k:mb-3 1440k:w-[40vw] 3xl:w-[100%] rk:w-[33vw] rk:text-[1.2vw] lgk:w-[37vw] ">
            {/* 1 card  */}
            <div className="rounded-lg flex flex-col p-4 w-full h-full lg:h-[150px] lg:w-[50%] bg-white xl:h-[150px] 8k:w-[30vh] 8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw] lgk:h-[12vw]">
              <div className="flex justify-between ">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/followers.png"
                  alt="followers"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[2vw] 1440k:w-[2vw] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:mt-1 8k:text-3xl 1440k:text-[1.5vw] 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw] ">
                    +10 %
                  </h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] font-bold 8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.5vw]  1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:pt-[1.2vw] ">
                8.4K+
              </div>
              <div className="text-black text-lg  lgk:text-[1.3vw] xl:text-sm 8k:text-2xl 8k:mt-9 8k:text-3xl 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw] ">
                Total followers reach
              </div>
            </div>
            {/* 2 card  */}
            <div className="rounded-lg flex flex-col p-4 lg:mr-[32px] lg:ml-[10px] bg-white w-full h-full lg:h-full lg:w-[50%] xl:h-[150px] 8k:w-[30vh]  8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw] lgk:h-[12vw] ">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/sales.png"
                  alt="sales"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[2vw] 1440k:w-[2vw] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-3xl 1440k:text-[1.5vw] 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw]">
                    -10 %
                  </h1>
                </div>
              </div>
              <div className="mt-[15px] mb-[10px] font-bold   8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.4vw] 1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:mt-[1.6vw] ">
                2.4K+
              </div>
              <div className="text-black text-sm lgk:text-[1.3vw]   8k:mt-9 8k:text-3xl 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw]  ">
                Sales increase weekly
              </div>
            </div>
          </div>
          {/* section 2  */}
          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0  lg:flex-row 8k:w-[53vh] 1440k:w-[40vw] 4k:w-[37vw] 3xl:w-[100%] rk:w-[33vw] rk:text-[1.2vw] lgk:w-[37vw]">
            {/* 3 card  */}
            <div className=" rounded-lg flex flex-col p-4 w-full h-full lg:h-[150px] lg:w-[50%] bg-white xl:h-[150px] 8k:w-[40vh] 8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw]">
              <div className="flex justify-between ">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/influencer_main.png"
                  alt="influencer"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[3vh] 1440k:w-[3vh] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:mt-1 8k:text-3xl 1440k:text-xl 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw] ">
                    +16.5 %
                  </h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] font-bold 8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.5vw] 1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:mt-[1.5vw]">
                5.44K+
              </div>
              <div className="text-black text-lg lgk:text-[1.3vw]  xl:text-sm 8k:text-2xl 8k:mt-9 8k:text-3xl 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw]">
                Total Influencers
              </div>
            </div>
            {/* 4 card  */}
            <div className=" rounded-lg flex flex-col p-4 lg:ml-[10px] lg:mr-[32px] bg-white w-full h-full lg:h-[150px] lg:w-[50%] xl:h-[150px] 8k:w-[40vh]  8k:h-[16vh] 8k:mb-2 8k:p-9 1440k:h-[12vw] 1440k:w-[25vw] 4k:h-[13vw] 3xl:h-[10vw] rk:h-[11vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:h-9 8k:w-9 1440k:h-9 1440k:w-9 4k:h-[2vw] 4k:w-[2vw]"
                  src="/Images/roi.png"
                  alt="roi"
                /> 
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:h-[2vh] 8k:w-[3vh] 1440k:h-[3vh] 1440k:w-[3vh] 4k:h-[1.5vw] 4k:w-[2vw]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-3xl 1440k:text-xl 1440k:font-bold 3xl:text-[1.3vw] 4k:text-[1vw]">
                    -19 %
                  </h1>
                </div>
              </div>
              <div className="mt-[15px] mb-[10px] font-bold   8k:text-3xl 8k:pt-9 1440k:text-[1.5vw] 1440k:pt-[0.4vw] 1440k:font-bold 3xl:pt-[0.5vw] 3xl:text-[1.3vw] 4k:text-[1.3vw] 4k:mt-[1.5vw] rk:mt-[1.5vw]">
                2.4K+
              </div>
              <div className="text-black text-sm lgk:text-[1.3vw]  8k:text-3xl 8k:mt-9 1440k:mt-1 1440k:text-[1.3vw] 4k:text-[1.2vw] 3xl:text-[1.3vw] 3xl:pt-[1.5vw] rk:text-[1.2vw]">
                Return on investment
              </div>
            </div>
          </div>
        </div>
        {/* graph  */}
        <div className=" rounded-lg xl:w-[554px] h-fit lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white lg:w-[50%] lg:h-[340px] 4k:w-[49%] 4k:h-[27vw] 8k:w-[50%] 8k:h-[34vh] 3xl:w-[80%] 3xl:h-[21vw] rk:w-[40vw] 1440k:w-[74%] 1440k:h-[25vw] lgk:w-[90%] lgk:h-[27vw] ">
          <div className="flex justify-between items-center">
            <h1 className="font-bond text-xs md:text-lg   8k:text-3xl 1440k:text-[1.3vw] 1440k:font-bold 3xl:text-[1.3vw]  4k:text-[1.2vw] 4k:font-bold ">
              Amount Spent Over the period of time
            </h1>
            <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] 8k:mr-9">
              <select className="w-full rounded-full py-2 pl-6 focus:outline-none 8k:mr-[2.5vh]">
                <option value="Year">Year</option>
                <option value="Month">Month</option>
              </select>
            </div>
          </div>

          {/* <div className="graph  md:w-full w-[99%] 8k:w-[55vh]  4k:w-[37vw] 4k:h-[40vw] 3xl:h-[15vw] 1440k:h-[40vw]  lgk:w-[100%]"> */}
          <ResponsiveContainer>
            <LineChart data={datach}>
              <Line type="monotone" dataKey="uv" stroke="#8884d8"  strokeWidth={8} />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
          {/* <Line options={options} data={data} />  */}
          {/* </div> */}
        </div>

      </div>

      <div className="flex py-[20px] justify-between ">
        <div className="bg-[#D9FDFF] rounded-lg text-[14px] p-2 w-fit 8k:text-3xl 4k:text-lg 3xl:text-[1vw]">
          Top Influencers
        </div>
        <div className="bg-[#FFE2E2] rounded-lg text-[14px] p-2 w-fit 8k:text-3xl 4k:text-lg 3xl:text-[1vw]">
          Hire More
        </div>
      {/*<img
          className="w-5 h-5 cursor-pointer"
          src="/Images/option.png"
          alt="option"
          onClick={() => setOption2(!option2)}
  />*/}
      </div>

      <div className="rounded-lg bg-white py-[10px] w-[95%] p-5 overflow-x-scroll md:w-full  ">
        <table className="table-fixed  md:gap-2 text-center lg:w-[100%]  ">
          <thead>
            <tr className=" ">
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                INFLUENCER
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Spend
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Content
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Revenue
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                CONV
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                ROI
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Engagement
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                IMV.ROI
              </th>
             {/* {optst1 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Rejected
                </th>
              )}
              {optst2 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Clicks
                </th>
              )}
              {optst3 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw]">
                  Total Followers
                </th>
              )}
              {optst4 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Conversions
                </th>
              )}
              {optst5 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  In Reviews
                </th>
              )} */}
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] ">
            {
            influencer.map((i) => (
            <tr key={i._id} className=" font-Poppins;">
              <td className="text-xs pt-[20px] md:text-lg text-[#707070] font-semibold">
                <div className="flex justify-center items-center space-x-[10px] ">
                  <img
                    className="w-6 h-6 rounded-full"
                    src={i.image}
                    alt="profile"
                  />
                  <h1 className=" 8k:text-2xl 3xl:text-[1vw] text-[#707070]; text-base">
                   {i.name}
                  </h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $5,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                3.71x
              </td>
            </tr>
             ))
             }
          </tbody>
        </table>
      {/*  {option2 && (
          <div className="bg-white md:text-lg text-xs md:right-2 border p-3 grid grid-cols-2 md:grid-cols-3 gap-3 flex-wrap">
            <h1
              className={
                optst4
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst4(!optst4);
              }}
            >
              Conversions
            </h1>
            <h1
              className={
                optst1
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst1(!optst1);
              }}
            >
              Rejected
            </h1>
            <h1
              className={
                optst5
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst5(!optst5);
              }}
            >
              In Review
            </h1>
            <h1
              className={
                optst2
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst2(!optst2);
              }}
            >
              Clicks
            </h1>
            <h1
              className={
                optst3
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst3(!optst3);
              }}
            >
              Total Followers
            </h1>
          </div>
            )} */}
      </div>
      
      <div className="flex mt-[10px] mb-[5px] justify-between">
      <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black">
        Active Campaigns
      </h1>
        <img
          className="w-5 h-5 cursor-pointer"
          src="/Images/option.png"
          alt="option"
          onClick={() => setOption1(!option1)}
        />
      </div>
      {/* table here  */}
      <div className="rounded-lg bg-white relative group py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full ">
        <table className="table-fixed  md:gap-2 text-center lg:w-[100%]">
          <thead>
            <tr className="">
              <th className="text-base pr-[10px] font-semibold md:text-[14px] 8k:text-2xl mobile:px-[20px] 3xl:text-[1vw]">
                Name
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Date
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Status
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                No. of Influencers
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Posted
              </th>
              {opts1 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Rejected
                </th>
              )}
              {opts2 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Clicks
                </th>
              )}
              {opts3 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold">
                  Total Followers
                </th>
              )}
              {opts4 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Conversions
                </th>
              )}
              {opts5 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  In Reviews
                </th>
              )}
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px]">
            {
            active.map((i) => (
            <tr key={i._id} className="">
              <td className="text-xs pt-[20px] md:text-lg font-semibold">
                {i.name}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] ">
              <span className={`text-sm ${statusColors[i.status]} font-semibold`}>
                  {i.status}
              </span>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $5,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                3.71x
              </td>
            </tr> 

             ))
             
             }
          </tbody>
        </table>

        {option1 && (
          <div className="bg-white md:text-lg shadow-md rounded-lg text-xs absolute top-0 md:right-2 border p-8 grid grid-cols-2 md:grid-cols-3 gap-7 flex-wrap ">
            <h1
              className={
                opts4
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpts4(!opts4);
              }}
            >
              Conversions
            </h1>
            <h1
              className={
                opts1
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpts1(!opts1);
              }}
            >
              Rejected
            </h1>
            <h1
              className={
                opts5
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpts5(!opts5);
              }}
            >
              In Review
            </h1>
            <h1
              className={
                opts2
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpts2(!opts2);
              }}
            >
              Clicks
            </h1>
            <h1
              className={
                opts3
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpts3(!opts3);
              }}
            >
              Total Followers
            </h1>
          </div>
        )}
      </div>

      <div className="flex mt-[10px] mb-[5px] justify-between">
      <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black">
        Finished Campaigns
      </h1>
        <img
          className="w-5 h-5 cursor-pointer"
          src="/Images/option.png"
          alt="option"
          onClick={() => setOption(!option)}
        />
      </div>

      <div className="rounded-lg bg-white relative group py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full ">
        <table className="table-fixed  md:gap-2 text-center lg:w-[100%]">
          <thead>
            <tr className="">
              <th className="text-base pr-[10px] font-semibold md:text-[14px] 8k:text-2xl mobile:px-[20px] 3xl:text-[1vw]">
                Name
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Date
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Status
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                No. of Influencers
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Posted
              </th>
              {opt1 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Rejected
                </th>
              )}
              {opt2 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Clicks
                </th>
              )}
              {opt3 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold">
                  Total Followers
                </th>
              )}
              {opt4 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Conversions
                </th>
              )}
              {opt5 && (
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  In Reviews
                </th>
              )}
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px]">
            {
            active.map((i) => (
            <tr key={i._id} className="">
              <td className="text-xs pt-[20px] md:text-lg font-semibold">
                {i.name}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] ">
              <span className={`text-sm ${statusColors[i.status]} font-semibold`}>
                  {i.status}
              </span>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $14,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                $5,160
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold">
                3.71x
              </td>
            </tr> 

             ))
             
             }
          </tbody>
        </table>

        {option && (
          <div className="bg-white md:text-lg shadow-md rounded-lg text-xs absolute top-0 md:right-2 border p-8 grid grid-cols-2 md:grid-cols-3 gap-7 flex-wrap ">
            <h1
              className={
                opt4
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpt4(!opt4);
              }}
            >
              Conversions
            </h1>
            <h1
              className={
                opt1
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpt1(!opt1);
              }}
            >
              Rejected
            </h1>
            <h1
              className={
                opt5
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpt5(!opt5);
              }}
            >
              In Review
            </h1>
            <h1
              className={
                opt2
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpt2(!opt2);
              }}
            >
              Clicks
            </h1>
            <h1
              className={
                opt3
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOpt3(!opt3);
              }}
            >
              Total Followers
            </h1>
          </div>
        )}
      </div>

      <h1 className="font-semibold text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black">Brand Awareness Influencers</h1>
      <div className="flex py-[20px] justify-between font-semibold">
        <div className="bg-[#D9FDFF] rounded-lg text-[14px] p-2 w-fit 8k:text-3xl 4k:text-lg 3xl:text-[1vw]">
          Top Influencers
        </div>
        <div className="bg-[#FFE2E2] rounded-lg text-[14px] p-2 w-fit 8k:text-3xl 4k:text-lg 3xl:text-[1vw]">
          Hire More
        </div>
      {/*<img
          className="w-5 h-5 cursor-pointer"
          src="/Images/option.png"
          alt="option"
          onClick={() => setOption2(!option2)}
  />*/}
      </div>

      <div className="rounded-lg bg-white py-[10px] w-[95%] p-5 overflow-x-scroll md:w-full  font-semibold">
        <table className="table-fixed  md:gap-2 text-center lg:w-[100%]  ">
          <thead>
            <tr className=" ">
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                INFLUENCER
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Content
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Spend
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Likes
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Comments
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Engagement Rate
              </th>
             {/* {optst1 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Rejected
                </th>
              )}
              {optst2 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Clicks
                </th>
              )}
              {optst3 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw]">
                  Total Followers
                </th>
              )}
              {optst4 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Conversions
                </th>
              )}
              {optst5 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  In Reviews
                </th>
              )} */}
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] ">
            {
            influencer.map((i) => (
            <tr key={i._id} className=" font-Poppins;">
              <td className="text-xs pt-[20px] md:text-lg text-[#707070]">
                <div className="flex justify-center items-center space-x-[10px] ">
                  <img
                    className="w-6 h-6 rounded-full"
                    src={i.image}
                    alt="profile"
                  />
                  <h1 className=" 8k:text-2xl 3xl:text-[1vw] text-[#707070]; text-base">
                   {i.name}
                  </h1>
                </div>
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                20
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                $1,723
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                21
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                $5,160
              </td>
            </tr>
             ))
             }
          </tbody>
        </table>
      {/*  {option2 && (
          <div className="bg-white md:text-lg text-xs md:right-2 border p-3 grid grid-cols-2 md:grid-cols-3 gap-3 flex-wrap">
            <h1
              className={
                optst4
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst4(!optst4);
              }}
            >
              Conversions
            </h1>
            <h1
              className={
                optst1
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst1(!optst1);
              }}
            >
              Rejected
            </h1>
            <h1
              className={
                optst5
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst5(!optst5);
              }}
            >
              In Review
            </h1>
            <h1
              className={
                optst2
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst2(!optst2);
              }}
            >
              Clicks
            </h1>
            <h1
              className={
                optst3
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst3(!optst3);
              }}
            >
              Total Followers
            </h1>
          </div>
            )} */}
      </div>

      <h1 className="font-semibold mb-5 text-[18px] 8k:text-3xl 8k:pt-5 3xl:text-[1vw] text-black">Brand Awareness Campaigns List</h1>
      <div className="rounded-lg bg-white py-[10px] w-[95%] p-5 overflow-x-scroll md:w-full font-semibold">
        <table className="table-fixed  md:gap-2 text-center lg:w-[100%]  ">
          <thead>
            <tr className=" ">
              <th className="text-sm pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Campaign Name
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Status
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                No. of Influencers
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Likes
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Comments
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Engagement Rate
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
                Spent
              </th>
              <th className="text-xs pr-[10px] md:text-[14px] 8k:text-3xl 3xl:text-[1vw]">
               Clicks
              </th>
             {/* {optst1 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Rejected
                </th>
              )}
              {optst2 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Clicks
                </th>
              )}
              {optst3 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw]">
                  Total Followers
                </th>
              )}
              {optst4 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  Conversions
                </th>
              )}
              {optst5 && (
                <th className="text-xs pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw]">
                  In Reviews
                </th>
              )} */}
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px] ">
            {
            influencer.map((i) => (
            <tr key={i._id} className=" font-Poppins;">
              <td className="text-xs pt-[20px] md:text-lg text-[#707070]">
                <div className="flex justify-center items-center">
                  <img
                    className="w-6 h-6 rounded-full"
                    src="/Images/cocacola.png"
                    alt="profile"
                  />
                  <h1 className=" 8k:text-2xl 3xl:text-[1vw] text-[#707070] text-xs font-medium;">
                   CoCola Campaign
                  </h1>
                </div>
              </td>
              <td className="text-sm font-semibold pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-green-400">
                Active
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                5
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                6
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                8
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                8
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                7000
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070]">
                49
              </td>
            </tr>
             ))
             }
          </tbody>
        </table>
      {/*  {option2 && (
          <div className="bg-white md:text-lg text-xs md:right-2 border p-3 grid grid-cols-2 md:grid-cols-3 gap-3 flex-wrap">
            <h1
              className={
                optst4
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst4(!optst4);
              }}
            >
              Conversions
            </h1>
            <h1
              className={
                optst1
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst1(!optst1);
              }}
            >
              Rejected
            </h1>
            <h1
              className={
                optst5
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst5(!optst5);
              }}
            >
              In Review
            </h1>
            <h1
              className={
                optst2
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst2(!optst2);
              }}
            >
              Clicks
            </h1>
            <h1
              className={
                optst3
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                setOptst3(!optst3);
              }}
            >
              Total Followers
            </h1>
          </div>
            )} */}
      </div>
    </div>
  );
}

export default Main;

/*         {option && (
          <div className="bg-white absolute top-8 right-2 border p-3 grid grid-cols-3 gap-3 flex-wrap ">
            <h1
              className={
                opt4
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : count === 3
                  ? "bg-white cursor-not-allowed py-2 px-3"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                0 > count < 3 ? (
                  <>
                    {setOpt4(false)} {setCount(count - 1)}
                  </>
                ) : (
                  <>
                    {setOpt4(true)} {setCount(count + 1)}
                  </>
                );
              }}
            >
              Conversions
            </h1>
            <h1
              className={
                opt1
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : count === 3
                  ? "bg-white cursor-not-allowed py-2 px-3"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                0 > count < 3 ? (
                  <>
                    {setOpt1(false)} {setCount(count - 1)}
                  </>
                ) : (
                  <>
                    {setOpt1(true)} {setCount(count + 1)}
                  </>
                );
              }}
            >
              Rejected
            </h1>
            <h1
              className={
                opt3
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : count === 3
                  ? "bg-white cursor-not-allowed py-2 px-3"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                0 > count < 3 ? (
                  <>
                    {setOpt3(false)} {setCount(count - 1)}
                  </>
                ) : (
                  <>
                    {setOpt3(true)} {setCount(count + 1)}
                  </>
                );
              }}
            >
              Total Followers
            </h1>
            <h1
              className={
                opt5
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : count === 3
                  ? "bg-white cursor-not-allowed py-2 px-3"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                0 > count < 3 ? (
                  <>
                    {setOpt5(false)} {setCount(count - 1)}
                  </>
                ) : (
                  <>
                    {setOpt5(true)} {setCount(count + 1)}
                  </>
                );
              }}
            >
              In Review
            </h1>
            <h1
              className={
                opt2
                  ? "bg-[#D9F1FF] cursor-pointer py-2 px-3 rounded-md border border-[#00A3FF]"
                  : count === 3
                  ? "bg-white cursor-not-allowed py-2 px-3"
                  : "bg-[#eceaea] cursor-pointer py-2 px-3 rounded-md"
              }
              onClick={() => {
                count > 3 || count !== 0 ? (
                  <>
                    {setOpt2(false)} {setCount(count - 1)}
                  </>
                ) : (
                  <>
                    {setOpt2(true)} {setCount(count + 1)}
                  </>
                );
              }}
            >
              Clicks
            </h1>
          </div>
        )}*/
