import React from "react";
import {useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";
// import axios from "axios";
// import Cookies from "js-cookie";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Line } from "react-chartjs-2";
// import { useNavigate } from "react-router-dom";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Chart.js Line Chart",
//     },
//   },
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: [
//         100000, 200000, 40000, 60000, 800000, 89000, 56090, 89090, 760000,
//         89000, 76090,
//       ],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//   ],
// };

const data = [
  { name: "Page A", uv: 120, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 280, pv: 1000, amt: 1200 },
  { name: "Page C", uv: 380, pv: 1800, amt: 1200 },
  { name: "Page D", uv: 150, pv: 2000, amt: 1200 },
];

function Affiliatehome() {
  // const navigate = useNavigate();
  // const [active, setActive] = useState([]);
  // const [option2, setOption2] = useState(false);
  // const [data, setData] = useState([]);
  const [active, setActive] = useState([]);
  const navigate=useNavigate();
  useEffect(() => {
    const get = async () => {
      try {
        const data1=[
          {
            "_id":1,
          "affiliate_id": 12345,
          "campaign_name": "Campaign Digital",
          "no_of_influencers": 10,
          "no_of_posts": 20,
          "total_commisions": 10000,
          "conversions_value":100,
          "conversions": 20,
          "Platform": "Instagram",
          "hits": 100,
          },
          {
            "_id":2,
            "affiliate_id": 12345,
            "campaign_name": "Campaign Digital",
            "no_of_influencers": 10,
            "no_of_posts": 20,
            "total_commisions": 10000,
            "conversions_value":100,
            "conversions": 20,
            "Platform": "Instagram",
            "hits": 100,
            },
            {
              "_id":3,
              "affiliate_id": 12345,
              "campaign_name": "Campaign Digital",
              "no_of_influencers": 10,
              "no_of_posts": 20,
              "total_commisions": 10000,
              "conversions_value":100,
              "conversions": 20,
              "Platform": "Instagram",
              "hits": 100,
              },
              {
                "_id":4,
                "affiliate_id": 12345,
                "campaign_name": "Campaign Digital",
                "no_of_influencers": 10,
                "no_of_posts": 20,
                "total_commisions": 10000,
                "conversions_value":100,
                "conversions": 20,
                "Platform": "Instagram",
                "hits": 100,
                },
                {
                  "_id":5,
                  "affiliate_id": 12345,
                  "campaign_name": "Campaign Digital",
                  "no_of_influencers": 10,
                  "no_of_posts": 20,
                  "total_commisions": 10000,
                  "conversions_value":100,
                  "conversions": 20,
                  "Platform": "Instagram",
                  "hits": 100,
                  },
        ]
        console.log(data1);
        setActive(data1);

        // setData(data);
        console.log("active",data1.data)
      } catch (error) {

        console.log(error);
      }
    };
    get();
  }, []);

  return (
    <div className="p-[20px] mr-[10px] md:p-2 space-y-[20px] flex flex-col items-center justify-center">
    <div className="flex lg:flex-row flex-col lg:w-[71vw] lg:space-y-0 space-y-[10px] 8k:w-[115vh] 4k:w-[77vw]  8k:w-[280vh] 3xl:mt-[1.5vw] lgk:pr-[2vw]">
        {/* graph  */}
        <div className="xl:w-[554px] lg:w-[300px] jk:w-[50vw] lg:ml-[10px] lg:flex flex-col justify-center px-[20px] py-[10px] bg-white 8k:w-[60vh] 8k:p-3 8k:mt-9 4k:w-[55vw] 4k:mt-9 3xl:w-[60%] rk:py-[1vw]
        1440k:h-[29vw]  lgk:w-[32vw] 2xl:w-[100%]">
          <div className="flex justify-between items-center">
            <h1 className="text-black font-extrabold text-md md:text-lg mr-[10px] 8k:text-3xl 8k:font-bold 4k:text-xl 4k:font-bold 3xl:text-[1.3vw] 3xl:font-bold rk:text-[1vw] rk:font-bold 1440k:font-bold lgk:font-bold lgk:text-[1.2vw] ">
              Sales Generated
            </h1>
            <div className=" mb-[10px] text-[#D9D9D9] w-fit border rounded-full flex justify-between space-x-[20px] 8k:text-xl 4k:text-lg mobile:w-[15vh] mobile:h-[4vh] mobile:text-xs lgk:text-[1vw] lgk:w-[10vw] lgk:px-0 ">
              <select className="w-full rounded-full py-2 pl-6 focus:outline-none mobile:p-1 lgk:px-[0.5vw] ">
                <option value="Year">Year</option>
                <option value="Month">Month</option>
              </select>
            </div>
          </div>

          {/* <div className="graph  md:w-full w-[99%] 3xl:h-[100%]  4k:h-[100%] 1440k:h-[100%] lgk:h-[100%] lgk:w-[100%] "> */}
          <ResponsiveContainer  >
            <LineChart data={data}>
              <Line type="monotone" dataKey="uv" stroke="#8884d8" strokeWidth={8} />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
            {/* <Line options={options} data={data} /> */}
           {/* </div> */}
        </div>
{/* section  */}
        <div className="xl:w-[484px] space-y-[25px] w-full lg:w-[350px] flex flex-col mb-[20px] lg:mb-0 px-[10px] pb-[20px] lg:pb-0 8k:w-[60vh] 4k:w-[40vw]  3xl:w-[40%]  3xl:h-[25vw]  4k:space-y-[1vw] 4k:ml-[1vw] rk:w-[100%]">
          {/* section-1 */}
          <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 4k:w-[26vw] 4k:mt-[2vw]  8k:ml-[2vh] 8k:mt-[2vh] 8k:h-[12vh]   3xl:text-[1.3vw] 3xl:h-[8vw]  lgk:text-[1vw] lgk:w-[40vw]">
{/* 1card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh] "
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw]  lgk:mb-[0]">8.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw] lgk:text-[1.2vw] ">
                Approved Influencers
              </div>
            </div>
{/* 2 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/click.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="text-lg font-extrabold text-black mt-[10px] mb-[10px] 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">2.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                clicks
              </div>
            </div>
          </div>

          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0  4k:w-[26vw] lg:flex-row  8k:ml-[2vh] 8k:my-[5vh]  8k:h-[12vh] 3xl:text-[1.3vw] 3xl:h-[8vw] lgk:w-[40vw]">
            {/* 3rd card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/sales.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">8.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total spent
              </div>
            </div>
{/* 4 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/influencer_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">2.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Pending Influencers
              </div>
            </div>
          </div>

          <div className="flex mt-[10px] flex-col space-y-[10px] lg:space-y-0 4k:w-[26vw] lg:flex-row 8k:ml-[2vh] 8k:mt-[2vh]  8k:h-[12vh]  lgk:w-[40vw] 3xl:text-[1.3vw] 3xl:h-[8vw]">
            {/* 5 card  */}
            <div className="flex flex-col p-4 w-full h-full lg:h-full lg:w-[60%] bg-white 1440k:w-[30vw] 1440k:h-[9vw] 3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/reach.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[3vh] 8k:h-[2vh]"
                    src="/Images/increment.png"
                    alt="increment"
                  />
                  <h1 className="text-green-500 8k:text-2xl">+10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">8.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Potential reach
              </div>
            </div>
{/* 6 card  */}
            <div className="flex flex-col p-4 lg:ml-[20px] bg-white w-full h-full lg:h-full lg:w-[60%] 1440k:w-[30vw] 1440k:h-[9vw]  3xl:w-[50%] rk:w-[60%] lgk:w-[40vw] lgk:h-[10vw]">
              <div className="flex justify-between">
                <img
                  className="w-5 h-5 8k:w-[3vh] 8k:h-[3vh]"
                  src="/Images/social_main.png"
                  alt="increase"
                />
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-[5px] 8k:w-[2vh] 8k:h-[2vh]"
                    src="/Images/decrement.png"
                    alt="increment"
                  />
                  <h1 className="text-red-500 8k:text-2xl">-10 %</h1>
                </div>
              </div>
              <div className="mt-[10px] mb-[10px] text-lg font-extrabold text-black 8k:text-3xl 8k:mt-7 1440k:text-[1.2vw] lgk:text-[1.2vw] lgk:mb-[0]">2.4K+</div>
              <div className="text-[#A0A0A0] font-bold text-xs lg:text-xs 8k:text-3xl 8k:mt-4 3xl:text-[1vw] 1440k:text-[1vw]  lgk:text-[1.2vw]">
                Total Posts
              </div>
            </div>
          </div>


        </div>
      </div>

      <div className="rounded-lg bg-white relative group py-[10px] md:w-[70vw] w-[85%] p-3 ml-2 overflow-x-scroll">
      {/* <div className="rounded-lg bg-white py-[10px] w-[100%] p-3 overflow-x-scroll md:w-full 8k:w-[60vw] sm:w-full md:w-[70vw] "> */}
      <div className="flex flex-row justify-between">
        <div>
          <h1 className="text-[#455468] font-bold text-lg p-8 ml-5">Analytics</h1>
        </div>
        <div className="p-5 space-x-2 flex items-center">
  <button className="border-box xl:text-md font-semibold text-[#455468] lg:text-base md:text-sm max-sm:text-xs xs:text-xs lg:px-4 lg:py-2 xl:px-4 xl:py-2 bg-white rounded-lg border border-solid border-[#D7DFE9] md:px-3 md:py-2 sm:px-2 sm:py-1">
    <div className="flex flex-row justify-between">
      <img src="/Images/Vectorbox_filter.png" alt="vector" className="h-5 pr-4" />
      <h1>Filter</h1>
    </div>
  </button>
  <button className="border-box font-semibold text-[#455468] xl:text-md lg:text-base md:text-sm max-sm:text-xs xs:text-xs lg:px-4 lg:py-2 xl:px-4 xl:py-2 bg-white rounded-lg border border-solid border-[#D7DFE9] md:px-3 md:py-2 sm:px-2 sm:py-1">
    <div className="flex flex-row justify-between">
      <img src="/Images/Vectorbox_filter.png" alt="vector" className="h-5 pr-4" />
      <h1>Search</h1>
    </div>
  </button>
</div>

      </div>
      <div className="rounded-lg bg-white relative group py-[10px] w-[95%] p-3 overflow-x-scroll md:w-full ">
        <table className="table-fixed  md:gap-2 text-center lg:w-[100%]">
          <thead>
            <tr className="">
              <th className="text-base pr-[10px] font-semibold md:text-[14px] 8k:text-2xl mobile:px-[20px] 3xl:text-[1vw]">
                Affiliate_id
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                Campaign Name
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                No. of Influencers
              </th>
              <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                No. of Posts
              </th>
              
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Total Commissions
                </th>
              
              
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Conversion Value
                </th>
             
             
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl  3xl:text-[1vw] font-semibold">
                  Conversions
                </th>
             
              
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Platform
                </th>
            
            
                <th className="text-base pr-[10px] md:text-[14px] 8k:text-2xl 3xl:text-[1vw] font-semibold">
                  Hits
                </th>
              
            </tr>
          </thead>
          <tbody className="my-[20px] mx-[20px] space-y-[20px]">
            {
            active.map((i) => (
            <tr key={i._id} className="">
              <td className="text-xs pt-[20px] md:text-lg font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.affiliate_id}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.campaign_name}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.no_of_influencers}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.no_of_posts}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.total_commisions}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.conversions_value}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.conversions}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.Platform}
              </td>
              <td className="text-xs pt-[20px]  md:text-[14px] 8k:text-2xl 3xl:text-[1vw] text-[#707070] font-semibold cursor-pointer" onClick={()=>{
                navigate(`/affiliate/123`);
              }}>
                {i.hits}
              </td>
            </tr> 

             ))
             
             }
          </tbody>
        </table>

      </div>
            </div>

    </div>
  );
}

export default Affiliatehome;
