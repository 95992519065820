import axios from "axios";
// import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";

function Report() {
  const [issue, setIssue] = useState("Influencer Issue");
  const [issue1, setIssue1] = useState(true);
  const [issue2, setIssue2] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const report = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        "https://backend-vsal.onrender.com/api/report",
        {
          name,
          email,
          issue,
          subject,
          message,
        },
        config
      );
      setName("");
      setEmail("");
      setMessage("");
      setIssue("");
      setSubject("");
      console.log(data);
      toast.success("Report submitted successfully");
    } catch (error) {
      toast.error("Something went wrong ");
    }
  };
  return (
    <div className="p-[20px] md:p-2 overflow-hidden lg:h-[calc(100vh-102px) mt-16">
      <form onSubmit={report} className="md:w-[500px] md:p-[28px] 8k:w-[40vh] 3xl:h-[35vw] 3xl:w-[25vw] 4k:w-[30vw] 4k:h-[35vw]">
        <div className="grid grid-cols-2 md:text-lg text-xs gap-2 bg-white rounded-3xl px-3 py-2  8k:text-2xl 3xl:h-[4vw] 3xl:items-center">
          <h1
            onClick={(e) => {
              setIssue("Influencer Issue");
              setIssue1(true);
              setIssue2(false);
            }}
            className={
              !issue1
                ? "py-2 cursor-pointer px-3 text-center text-[#b2aeae] rounded-3xl bg-blue-100 8k:text-3xl 3xl:text-[1vw] transition duration-300 ease-in-out hover:bg-sky-300"
                : "py-2 px-3 cursor-pointer rounded-3xl text-center text-white bg-[#00A3FF] 8k:text-3xl 3xl:text-[1vw]"
            }
          >
            Influencer Issue
          </h1>
          <h1
            className={
              !issue2
                ? "py-2 cursor-pointer px-3 text-center text-[#b2aeae] rounded-3xl bg-blue-100 8k:text-3xl 3xl:text-[1vw] transition duration-300 ease-in-out hover:bg-sky-300"
                : "py-2 px-3 cursor-pointer rounded-3xl text-center text-white bg-[#00A3FF] 8k:text-3xl 3xl:text-[1vw]"
            }
            onClick={(e) => {
              setIssue("Campaign Issue");
              setIssue2(true);
              setIssue1(false);
            }}
          >
            Campaign Issue
          </h1>
        </div>
        <h1 className="text-[#b2aeae] my-[10px] text-center 8k:text-3xl 3xl:text-[0.8vw]">
          We are here for you! How can we help?
        </h1>
        <div>
          {/* <h1 className="font-[16px]">Your Name</h1>
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="text"
            placeholder="Please enter your name"
          />
          <h1 className="font-[16px]">Email</h1>
          <input
            className="bg-white pl-6 text-[#b2aeae] my-[10px] p-3 w-full focus:outline-none"
            type="email"
            placeholder="Please enter your Email"
          /> */}
          <h1 className="font-[16px] 8k:text-3xl 8k:mt-9 8k:mb-5  3xl:text-[1.1vw] 3xl:mt-[1.1vw] 4k:text-[1.1vw]  4k:mt-[1.1vw] 1440k:text-[1.4vw] 1440k:mt-[1.4vw] ">Name </h1>
          <textarea
            className="w-full mb-[4px] pl-2 focus:outline-none p-1 8k:text-3xl  3xl:text-[1.1vw] 4k:text-[1.1vw]  1440k:text-[1.4vw]"
            placeholder="Enter your name here"
            rows={1}
            value={name}
            onChange={(e) => setMessage(e.target.value)}
          />
          <h1 className="font-[16px] 8k:text-3xl 8k:mt-9 8k:mb-5  3xl:text-[1.1vw] 3xl:mt-[1.1vw] 4k:text-[1.1vw]  4k:mt-[1.1vw] 1440k:text-[1.4vw] 1440k:mt-[1.4vw] ">Email </h1>
          <textarea
            className="w-full mb-[4px] pl-2 focus:outline-none p-1 8k:text-3xl  3xl:text-[1.1vw] 4k:text-[1.1vw]  1440k:text-[1.4vw]"
            placeholder="Enter your email here"
            rows={1}
            value={email}
            onChange={(e) => setMessage(e.target.value)}
          />
          <h1 className="font-[16px] 8k:text-3xl 8k:mt-9 8k:mb-5 3xl:text-[1.1vw] 4k:text-[1.1vw] 1440k:text-[1.4vw]  ">Subject </h1>
          <div className="w-full mb-[10px] text-[#b2aeae] flex justify-between space-x-[20px] 8k:text-3xl 3xl:text-[1vw] 4k:text-[1.1vw]  1440k:text-[1.2vw]">
            <select
              onChange={(e) => setSubject(e.target.value)}
              className="w-full py-2 pl-6 focus:outline-none "
            >
              <option className="8k:text-xl 3xl:text-[0.5vw] 4k:text-[0.8vw]  1440k:text-[1vw] ">No issue faced</option>
              <option className="8k:text-xl 3xl:text-[0.5vw] 4k:text-[0.8vw]  1440k:text-[1vw]" value="Facebook">Facebook</option>
              <option className="8k:text-xl 3xl:text-[0.5vw] 4k:text-[0.8vw]  1440k:text-[1vw]" value="Youtube">Youtube</option>
              <option className="8k:text-xl 3xl:text-[0.5vw] 4k:text-[0.8vw]  1440k:text-[1vw]" value="Instagram">Instagram</option>
            </select>
          </div>
          <h1 className="font-[16px] 8k:text-3xl 8k:mt-9 8k:mb-5  3xl:text-[1.1vw] 3xl:mt-[1.1vw] 4k:text-[1.1vw]  4k:mt-[1.1vw] 1440k:text-[1.4vw] 1440k:mt-[1.4vw] ">Message </h1>
          <textarea
            className="w-full mb-[10px] pl-6 focus:outline-none p-3 8k:text-3xl  3xl:text-[1.1vw] 4k:text-[1.1vw]  1440k:text-[1.4vw]"
            placeholder="Enter your message here"
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <input
            className="text-center cursor-pointer py-2 text-white w-full my-[10px] rounded-lg bg-[#00A3FF] 8k:text-4xl 8k:p-4  3xl:text-[1.1vw] 4k:text-[1.1vw]  1440k:text-[1.4vw] transition duration-300 ease-in-out hover:bg-blue-900"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}

export default Report;
